import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ethers } from 'ethers';

import { contractAddresses } from '../../../config';
import currencyAbi from '../../../abi/Fight.json';
import currencyMinterAbi from '../../../abi/FightMinter.json';

/**
 * This thunk instance the erc20 like token, and returns the basic required data from it.
 */
export const initToken = createAsyncThunk(
  'initToken',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action, thunkAPI: any) => {
    try {
      const { provider, signer, address } = thunkAPI.getState().connect;
      const tokenAddress = contractAddresses.Fight;
      const currencyMinterAddress = contractAddresses.FightMinter;

      const tokenInstance = new ethers.Contract(
        tokenAddress,
        currencyAbi,
        provider,
      );

      const tokenSignerInstance = new ethers.Contract(
        tokenAddress,
        currencyAbi,
        signer,
      );

      const tokenMinterInstance = new ethers.Contract(
        currencyMinterAddress,
        currencyMinterAbi,
        signer,
      );

      const balanceBigNumber: ethers.BigNumber = await tokenInstance.balanceOf(
        address,
      );

      const balance = Number(ethers.utils.formatEther(balanceBigNumber));
      return {
        balance,
        tokenInstance,
        tokenSignerInstance,
        tokenMinterInstance,
      };
    } catch (error) {
      console.log('Error initializing token', error);
      Promise.resolve(setTimeout(() => {}, 1000));
      thunkAPI.dispatch(initToken());
      throw error;
    }
  },
);

/**
 * This thunk fetches the connected user balance of fight
 */
export const fetchBalance = createAsyncThunk(
  'fetchBalance',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action, thunkAPI: any) => {
    try {
      const { address } = thunkAPI.getState().connect;
      const { tokenInstance } = thunkAPI.getState().token;
      const balanceBigNumber: ethers.BigNumber = await tokenInstance.balanceOf(
        address,
      );

      const balance = Number(ethers.utils.formatEther(balanceBigNumber));
      return {
        balance,
      };
    } catch (error) {
      console.log('Error fetching balance', error);
      Promise.resolve(setTimeout(() => {}, 1000));
      thunkAPI.dispatch(fetchBalance());
      throw error;
    }
  },
);

const tokenSlice = createSlice({
  name: 'tokenReducer',
  initialState: {
    balance: 0,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tokenInstance: null as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tokenSignerInstance: null as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tokenMinterInstance: null as any,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(initToken.fulfilled, (state, action) => {
        state.balance = action.payload.balance;
        state.tokenInstance = action.payload.tokenInstance;
        state.tokenSignerInstance = action.payload.tokenSignerInstance;
        state.tokenMinterInstance = action.payload.tokenMinterInstance;
      })
      .addCase(fetchBalance.fulfilled, (state, action) => {
        state.balance = action.payload.balance;
      });
  },
});

export const tokenReducer = tokenSlice.reducer;
