import React from 'react';

import styles from './SkillBox.module.css';

interface SkillBoxProps {
  skills: {
    punchPower: string;
    kickPower: string;
    speed: string;
    stamina: string;
  };
  responsive?: boolean;
}

/**
 * Skill box component, it will render the bar points for each of the stats of the character
 * @param {string} punchPower
 * @param {string} kickPower
 * @param {string} speed
 * @param {string} stamina
 * @param {boolean} responsive booleana to set if the skillbox will be used in a card. If false it will be responsive for pop ups
 *
 */
const SkillBox: React.FC<SkillBoxProps> = ({ skills, responsive }) => {
  SkillBox.defaultProps = {
    responsive: undefined,
  };
  function buildStat(amount: number) {
    const a = [];
    if (amount >= 0 && amount < 6) {
      for (let index = 0; index < amount; index += 1) {
        a.push(
          <img
            alt=""
            key={`Skillbar${a.length}`}
            src="assets/Score/Skills/Point.svg"
          />,
        );
      }

      if (amount < 5) {
        for (let index = 0; index < 5 - amount; index += 1) {
          a.push(
            <img
              alt=""
              key={`Skillbar${a.length}`}
              src="assets/Score/Skills/Empty.svg"
            />,
          );
        }
      }
    }
    return a;
  }

  const punchPowerStat = buildStat(Number(skills.punchPower));
  const kickPowerStat = buildStat(Number(skills.kickPower));
  const speedStat = buildStat(Number(skills.speed));
  const staminaStat = buildStat(Number(skills.stamina));

  return (
    <div
      className={responsive ? styles.SkillCardContainer : styles.SkillContainer}
    >
      <span className={styles.SkillLine}>
        <span>Speed</span>
        <div className={styles.Bars}>{speedStat}</div>
      </span>
      <span className={styles.SkillLine}>
        <span>Stamina</span>
        <div className={styles.Bars}>{staminaStat}</div>
      </span>

      <span className={styles.SkillLine}>
        <span>Kick</span>

        <div className={styles.Bars}>{kickPowerStat}</div>
      </span>

      <span className={styles.SkillLine}>
        <span>Punch</span>
        <div className={styles.Bars}>{punchPowerStat}</div>
      </span>
    </div>
  );
};

export default SkillBox;
