import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import { Store } from 'react-notifications-component';
import { successfulRefetch } from '../minterReducer/minterReducer';
import { contractAddresses, delayAlerts } from '../../../config';
import XConsoleABI from '../../../abi/XConsole.json';
import characterMinterAbi from '../../../abi/CharacterMinter.json';

import { Claim } from '../../../lib/types';

/**
 * This thunk fetches the connected user balance of fight
 */
export const fetchConsoles = createAsyncThunk(
  'fetchConsoles',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action, thunkAPI: any) => {
    try {
      const { provider, address } = thunkAPI.getState().connect;
      const { axiosInstance } = thunkAPI.getState().api;

      // const xConsoles = new ethers.Contract(
      //   contractAddresses.XConsoles,
      //   XConsoleABI,
      //   provider,
      // );

      const characterMinter = new ethers.Contract(
        contractAddresses.CharacterMinter,
        characterMinterAbi,
        provider,
      );

      const merkleProof = await axiosInstance.get(`/merkleTree/${address}`);
      const hasMerkleClaimed = await characterMinter.whitelistClaimed(address);

      const merkleClaims =
        merkleProof.data && merkleProof.data.length > 0 && !hasMerkleClaimed;
      const normalClaims = [] as Claim[];
      const genesisClaims = [] as Claim[];
      // const wallet: ethers.BigNumber[] = await xConsoles.walletOfOwner(address);

      // const pushClaim = async (claimObject: Claim) => {
      //   if (claimObject.tokenId % 20 === 0) {
      //     genesisClaims.push(claimObject);
      //   } else {
      //     normalClaims.push(claimObject);
      //   }
      // };

      // await Promise.all(
      //   wallet.map(async (element: ethers.BigNumber) => {
      //     const pending: ethers.BigNumber =
      //       await characterMinter.xConsolePendingClaims(element);
      //     const claimObject = {
      //       tokenId: element.toNumber(),
      //       pendingClaims: pending.toNumber(),
      //     };
      //     pushClaim(claimObject);
      //   }),
      // );

      return {
        normalClaims,
        genesisClaims,
        merkleClaims,
        merkleProof,
      };
    } catch (error) {
      console.log('Error fetching balance of consoles.');
      Promise.resolve(setTimeout(() => {}, 1000));
      thunkAPI.dispatch(fetchConsoles());
      throw error;
    }
  },
);

/**
 * This thunk fetches the connected user balance of fight
 */
export const claimXConsoles = createAsyncThunk(
  'claimXConsoles',
  async (
    action: { isMerkle: boolean; tokenId?: number; amount: number },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    thunkAPI: any,
  ) => {
    try {
      const { signer, provider, address } = thunkAPI.getState().connect;
      const { merkleProof } = thunkAPI.getState().freeMint;

      const characterMinter = new ethers.Contract(
        contractAddresses.CharacterMinter,
        characterMinterAbi,
        signer,
      );

      const sendClaim = async () => {
        if (action.isMerkle) {
          const tx = await characterMinter.whitelistMint(address, merkleProof);
          return tx;
        }
        const tx = await characterMinter.xConsoleHolderMint(
          address,
          action.tokenId,
          action.amount,
        );
        return tx;
      };

      const tx = await sendClaim();
      await provider.waitForTransaction(tx.hash);
      thunkAPI.dispatch(fetchConsoles());
      thunkAPI.dispatch(successfulRefetch());
    } catch (error) {
      console.log('Error claiming consoles.', error);
      throw error;
    }
  },
);

const freeMintInitialState = {
  normalClaims: null as Claim[] | null,
  genesisClaims: null as Claim[] | null,
  merkleClaims: false,
  merkleProof: null as string | null,
  totalTx: 0,
};

const freeMint = createSlice({
  name: 'claimReducer',
  initialState: freeMintInitialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchConsoles.fulfilled, (state, action) => {
        state.normalClaims = action.payload.normalClaims;
        state.genesisClaims = action.payload.genesisClaims;
        state.merkleClaims = action.payload.merkleClaims;
        state.merkleProof = action.payload.merkleProof.data;
      })
      .addCase(claimXConsoles.fulfilled, state => {
        state.totalTx += 1;
        Store.addNotification({
          title: 'Claim',
          message: 'You have claimed your free character',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: delayAlerts,
            onScreen: true,
          },
        });
      })
      .addCase(claimXConsoles.rejected, state => {
        state.totalTx += 1;
        Store.addNotification({
          title: 'Claim',
          message: 'Error trying to claim',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: delayAlerts,
            onScreen: true,
          },
        });
      });
  },
});

export const freeMintReducer = freeMint.reducer;
