import React, { useState } from 'react';
import Nav from 'react-bootstrap/esm/Nav';
import { LinkContainer } from 'react-router-bootstrap';

import { OwnedBackgrounds } from '../../lib/types';
import { handlePurchase } from '../../lib/utils';
import { useAppDispatch } from '../../redux/hooks/reduxHooks';
import { APP } from '../../routes';
import styles from './BackgroundCard.module.css';
import ImageLoader from '../ImageLoader/ImageLoader';

/**
 * Background card component. Props:
 * @param {string} name to show on top of the card. Needs to be the name of the character
 * @param {number?} id token id of the pass
 * @param {number?} amount amount of owned backgrounds for the myNfts screen carousel
 * @param {string?} price to buy the pass. This will also change the UI of the card, displaying buy UI if price is passed
 * @param {string} description to display in the card
 * @param {string} background url to display the image in the card. The background referenced MUST respect the width and height for the card
 */
const BackgroundCard: React.FC<OwnedBackgrounds> = ({
  name,
  id,
  price,
  amount,
  description,
  background,
}) => {
  const [amountToBuy, setAmountToBuy] = useState(0);
  const dispatch = useAppDispatch();
  const handleIncrease = () => {
    if (amountToBuy < 10) {
      setAmountToBuy(amountToBuy + 1);
    }
  };
  const handleDecrease = () => {
    if (amountToBuy > 0) {
      setAmountToBuy(amountToBuy - 1);
    }
  };

  return (
    <div className={styles.BackgroundContainer}>
      <div className={styles.Top}>
        <span className={styles.Title}>{name}</span>
        {price && <span className={styles.Price}>{`$Fight: ${price}`}</span>}
        {amount && (
          <span className={styles.Price}>{`You have: ${amount}`}</span>
        )}
      </div>
      <div className={styles.Bottom}>
        <ImageLoader className={styles.Background} src={background} alt="" />
        {price && (
          <div className={styles.ButtonsContainer}>
            <span className={styles.Amount}>
              <button
                type="button"
                aria-label="Decrease amount to buy"
                className={styles.AmountButton}
                onClick={handleDecrease}
              >
                -
              </button>
              <div className={styles.AmountText}>{amountToBuy}</div>
              <button
                type="button"
                aria-label="Increase amount to buy"
                className={styles.AmountButton}
                onClick={handleIncrease}
              >
                +
              </button>
            </span>
            <LinkContainer to={APP.BUY} className={styles.BuyButton}>
              <Nav.Link
                className={styles.BuyButton}
                onClick={() =>
                  handlePurchase(
                    amountToBuy,
                    1,
                    dispatch,
                    {
                      name,
                      price,
                      description,
                      background,
                      id,
                    },
                    undefined,
                    undefined,
                    id,
                  )
                }
              >
                Buy
              </Nav.Link>
            </LinkContainer>
          </div>
        )}
      </div>
    </div>
  );
};

export default BackgroundCard;
