// eslint-disable-next-line @typescript-eslint/no-explicit-any
import React, { useEffect, useState } from 'react';
import Loader from '../Loader/Loader';

interface ImageLoaderProps {
  alt: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  className?: any;
  src?: string;
}

/**
 * Component using Loader component to pre load image displaying the loader.
 * @param alt image alt
 * @param className image className to apply css
 * @param src of the image to load and display a loader until onLoad triggers
 */
const ImageLoader: React.FC<ImageLoaderProps> = ({ alt, className, src }) => {
  ImageLoader.defaultProps = {
    className: '',
    src: '',
  };
  const [loading, setLoading] = useState(true);
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    const img = new Image();
    const source = src || '';
    img.src = source;
    img.onload = () => {
      setImgSrc(source);
      setLoading(false);
    };
  }, [src]);

  return loading ? (
    <Loader forImages />
  ) : (
    <img alt={alt} src={imgSrc} className={className} />
  );
};
export default ImageLoader;
