import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Carousel from 'react-multi-carousel';

import CharacterCard from '../CharacterCard/CharacterCard';
import {
  CharacterDTO,
  OwnedAudios,
  OwnedBackgrounds,
  PassesIds,
} from '../../lib/types';
import styles from './CarouselWrap.module.css';
import 'react-multi-carousel/lib/styles.css';
import BackgroundCard from '../BackgroundCard/BackgroundCard';
import AudioCard from '../AudioCard/AudioCard';
import PassCard from '../PassCard/PassCard';

import { colorList, fightPerId } from '../../lib/dtos';
import { useAppSelector } from '../../redux/hooks/reduxHooks';
import ClaimCard from '../ClaimCard/ClaimCard';
import { APP } from '../../routes';

interface CarouselProps {
  characterDTO?: CharacterDTO[];
  backgroundDTO?: OwnedBackgrounds[];
  passesDTO?: {
    passIdOwned: PassesIds[];
  };
  audioDTO?: OwnedAudios[];
  mode: number;
  dots: boolean;
}

/**
 * Carousel wrap component to display special ones depending on the dto you pass. Props:
 * @param {CharacterDTO[] | undefined} characterDTO dto to display the characters, may be undefined
 * @param {BackgroundDTO[] | undefined} backgroundDTO  dto to display the backgrounds, may be undefined
 * @param {OwnedAudios[] | undefined} audioDTO  dto to display the audios, may be undefined
 * @param {{ passIdOwned: PassesIds[] } | null} passesDTO  dto to display the passes, may be undefined
 * @param {number} mode to display the desired carousel.
 *  May be: 0 for characters | 1 for backgrounds | 2 for audiotaunts | 3 for passes to buy | 4 for available free claims for user | 5 for passes owned by user
 * @param {boolean} dots if the carousel needs the dots

 */
const CarouselWrap: React.FC<CarouselProps> = ({
  characterDTO,
  backgroundDTO,
  audioDTO,
  passesDTO,
  mode,
  dots,
}) => {
  CarouselWrap.defaultProps = {
    characterDTO: undefined,
    backgroundDTO: undefined,
    audioDTO: undefined,
    passesDTO: undefined,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [cards, setCards] = useState<JSX.Element[] | JSX.Element[][] | null>(
    [],
  );
  const { passMetadata } = useAppSelector(state => state.nft);
  const { merkleClaims, normalClaims, genesisClaims } = useAppSelector(
    state => state.freeMint,
  );
  const location = useLocation();
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: mode === 0 ? 4 : location.pathname === APP.FIGHT ? 4 : 6,
      partialVisibilityGutter: 0,
    },
    largeDesktop: {
      breakpoint: { max: 3000, min: 2160 },
      items: mode === 0 ? 3 : location.pathname === APP.FIGHT ? 3 : 6,
      partialVisibilityGutter: 0,
    },
    mediumDesktop: {
      breakpoint: { max: 2160, min: 1440 },
      items: mode === 0 ? 3 : 3,
      partialVisibilityGutter: 0,
    },
    smallDesktop: {
      breakpoint: { max: 1440, min: 1024 },
      items: mode === 0 ? 2 : 2,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      partialVisibilityGutter: 0,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 0,
    },
  };

  useEffect(() => {
    switch (mode) {
    case 0: {
      if (characterDTO) {
        const map = characterDTO.map((element: CharacterDTO, index) => (
          <CharacterCard
            key={element.name}
            name={element.name}
            description={element.description}
            character={element.character}
            tokenId={element.tokenId}
            score={element.score ? element.score : undefined}
            skills={element.skills ? element.skills : undefined}
            color={colorList[index % 5]}
          />
        ));
        setCards(map);
      }
      break;
    }
    case 1: {
      if (backgroundDTO) {
        const map = backgroundDTO.map((element: OwnedBackgrounds) => (
          <BackgroundCard
            key={element.name}
            name={element.name}
            id={element.id}
            amount={element.amount ? element.amount : undefined}
            description={element.description}
            background={element.background}
            price={element.price}
          />
        ));
        setCards(map);
      }
      break;
    }
    case 2: {
      if (audioDTO) {
        const map = audioDTO.map((element: OwnedAudios) => (
          <AudioCard
            key={element.characterName}
            title={element.title}
            id={element.id}
            price={element.price}
            amount={element.amount ? element.amount : undefined}
            characterName={element.characterName}
            audioTrack={element.audioTrack}
            viewMode={location.pathname === APP.MYNFTS}
          />
        ));
        setCards(map);
      }
      break;
    }
    case 3: {
      const passesCards = [
        <PassCard
          key="PassToBuy10"
          fightsAmount={fightPerId[0]}
          passId={0}
          viewMode={false}
        />,
        <PassCard
          key="PassToBuy20"
          fightsAmount={fightPerId[1]}
          passId={1}
          viewMode={false}
        />,
        <PassCard
          key="PassToBuy50"
          fightsAmount={fightPerId[2]}
          passId={2}
          viewMode={false}
        />,
        <PassCard
          key="PassToBuy100"
          fightsAmount={fightPerId[3]}
          passId={3}
          viewMode={false}
        />,
      ];
      setCards(passesCards);
      break;
    }

    case 4: {
      const map = [] as JSX.Element[];

      if (merkleClaims) {
        map.push(
          <ClaimCard key="ClaimMerkle" mode={0} claimableAmount={1} />,
        );
      }

      if (normalClaims && normalClaims.length > 0) {
        normalClaims.forEach(element => {
          if (element.pendingClaims > 0) {
            map.push(
              <ClaimCard
                key={`ClaimXConsoles${element.tokenId}`}
                mode={1}
                tokenId={element.tokenId}
                claimableAmount={element.pendingClaims}
              />,
            );
          }
        });
      }

      if (genesisClaims && genesisClaims.length > 0) {
        genesisClaims.forEach(element => {
          if (element.pendingClaims > 0) {
            map.push(
              <ClaimCard
                key={`ClaimGenesis${element.tokenId}`}
                mode={2}
                tokenId={element.tokenId}
                claimableAmount={element.pendingClaims}
              />,
            );
          }
        });
      }

      setCards(map);
      break;
    }

    case 5: {
      if (passesDTO && passesDTO.passIdOwned) {
        const allPasses = [] as {
            passTokenId: number;
            passType: number;
            passIndex: number;
          }[];

        passesDTO.passIdOwned.forEach(
          (passIds: PassesIds, passType: number) => {
            passIds.forEach((element: number, passIndex: number) => {
              allPasses.push({ passTokenId: element, passType, passIndex });
            });
          },
        );

        allPasses.sort(
          (
            a: {
                passTokenId: number;
                passType: number;
                passIndex: number;
              },
            b: {
                passTokenId: number;
                passType: number;
                passIndex: number;
              },
          ) => {
            const remainingA = Number(
              passMetadata[a.passType][a.passIndex].attributes[0].value,
            );
            const remainingB = Number(
              passMetadata[b.passType][b.passIndex].attributes[0].value,
            );

            return remainingB - remainingA;
          },
        );

        const passesCards = allPasses.map(
          (pass: {
              passTokenId: number;
              passType: number;
              passIndex: number;
            }) => (
            <PassCard
              key={`${pass.passTokenId} MyPassCard`}
              tokenId={pass.passTokenId.toString()}
              fightsAmount={fightPerId[pass.passType]}
              remainingFights={Number(
                passMetadata[pass.passType][pass.passIndex].attributes[0]
                  .value,
              )}
              passId={pass.passType}
              viewMode
            />
          ),
        );

        setCards(passesCards);
      }
      break;
    }

    default: {
      break;
    }
    }
  }, [
    genesisClaims,
    merkleClaims,
    normalClaims,
    characterDTO,
    passMetadata,
    backgroundDTO,
    audioDTO,
    mode,
    location.pathname,
    passesDTO,
  ]);

  return (
    <Carousel
      swipeable
      draggable
      showDots={dots}
      responsive={responsive}
      partialVisible
      ssr
      keyBoardControl
      transitionDuration={500}
      itemClass={styles.CarouselItem}
      containerClass={styles.CarouselContainer}
    >
      {cards}
    </Carousel>
  );
};

export default CarouselWrap;
