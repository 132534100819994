import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { apiReducer } from '../reducers/apiReducer/apiReducer';

import { connectReducer } from '../reducers/connectReducer/connectReducer';
import { marketReducer } from '../reducers/marketReducer/marketReducer';
import { minterReducer } from '../reducers/minterReducer/minterReducer';
import { nftReducer } from '../reducers/nftReducer/nftReducer';
import { roomReducer } from '../reducers/roomReducer/roomReducer';
import { tokenReducer } from '../reducers/tokenReducer/tokenReducer';
import { freeMintReducer } from '../reducers/freeMintReducer/freeMintReducer';

const store = configureStore({
  reducer: {
    connect: connectReducer,
    token: tokenReducer,
    nft: nftReducer,
    minter: minterReducer,
    market: marketReducer,
    api: apiReducer,
    room: roomReducer,
    freeMint: freeMintReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
