import React from 'react';
import { AnimatedOnScroll } from 'react-animated-css-onscroll';
import { GreenButton } from '../../../components';
import { APP } from '../../../routes';

import styles from './Home.module.css';
import { delayPageLoad } from '../../../config';

const Home: React.FC = () => (
  <section className={styles.HomeContainer}>
    <div className={styles.Mask}>
      <video src="assets/Landing/Home/intro.mp4" id="video" autoPlay height="284px" muted loop controls />
    </div>
    <div className={styles.Kid} />
    {process.env.REACT_APP_FIGHT_ACTIVE === 'true' && (
      <div className={styles.PlayButton}>
        <AnimatedOnScroll
          animationIn="fadeInDown"
          animateOnce
          animationInDelay={delayPageLoad + 200}
        >
          <div className={styles.BannerButton}>
            <GreenButton route={APP.FIGHT} text="Fight now!" />
          </div>
        </AnimatedOnScroll>
      </div>
    )}
  </section>
);

export default Home;
