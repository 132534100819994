import React, { useEffect, useState } from 'react';

import { ScoreBoardDTO } from '../../lib/types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/reduxHooks';
import {
  changeScoreFetch,
  fetchPersonalScoreboard,
  fetchGlobalScoreboard,
} from '../../redux/reducers/apiReducer/apiReducer';
import BoardItem from '../BoardItem/BoardItem';
import Loader from '../Loader/Loader';
import styles from './ScoreBoard.module.css';

interface ScoreBoardProps {
  scoreBoardDTO: ScoreBoardDTO[] | null;
  displayAmount: number;
  isGlobal?: boolean;
  isTop?: boolean;
}

/**
 * Scoreboard component. Props:
 * @param {ScoreBoardDTO[] | null} scoreBoardDTO dto of the scoreboard
 * @param {number} displayAmount number of rows to display
 * @param {boolean} isGlobal if defined it will display the board using the start and length of the global and not the personal
 * @param {boolean} isTop if defined will only display the first page without pagination buttons. Like a top 10 from global if isGlobal its defined
 */
const ScoreBoard: React.FC<ScoreBoardProps> = ({
  scoreBoardDTO,
  displayAmount,
  isGlobal,
  isTop,
}) => {
  ScoreBoard.defaultProps = {
    isTop: false,
    isGlobal: false,
  };
  const { globalStart, globalLength, personalStart, personalLength } =
    useAppSelector(state => state.api);

  const { charactersIdOwned, charactersSupply } = useAppSelector(
    state => state.nft,
  );

  const [loading, setLoading] = useState(false);
  const [max, setMax] = useState(999);
  const [start, setStart] = useState(isGlobal ? globalStart : personalStart);
  const [currentPage, setCurrentPage] = useState(1);
  const [length, setLength] = useState(
    isGlobal ? globalLength : personalLength,
  );

  const dispatch = useAppDispatch();

  /** It handles the fetch request when trying to see more of the scoreboard */
  const handleFetch = (increase: boolean) => {
    if (increase ? true : start >= length) {
      setLoading(true);
      dispatch(
        changeScoreFetch({
          start: increase ? start + length : start - length,
          length,
          isGlobal,
        }),
      );
      if (isGlobal) {
        dispatch(fetchGlobalScoreboard());
      } else {
        dispatch(fetchPersonalScoreboard());
      }
    }
  };

  useEffect(() => {
    if (charactersSupply) {
      setMax(Math.ceil(charactersSupply / displayAmount));
    }
  }, [charactersSupply, displayAmount]);

  useEffect(() => {
    setLoading(false);
  }, [scoreBoardDTO]);

  useEffect(() => {
    setCurrentPage(start / 10 + 1);
  }, [start]);

  useEffect(() => {
    setStart(isGlobal ? globalStart : personalStart);
    setLength(isGlobal ? globalLength : personalLength);
  }, [
    globalStart,
    globalLength,
    personalStart,
    personalLength,
    scoreBoardDTO,
    isGlobal,
  ]);

  /** Select the trophy for the top scoreboard positions */
  function selectTrophy(ranking: number) {
    switch (ranking) {
    case 1: {
      const trophyImage = 'assets/Score/Trophy1.svg';
      const rankingFixed = '1 st';
      return { trophyImage, rankingFixed };
    }
    case 2: {
      const trophyImage = 'assets/Score/Trophy2.svg';
      const rankingFixed = '2 nd';
      return { trophyImage, rankingFixed };
    }
    case 3: {
      const trophyImage = 'assets/Score/Trophy3.svg';
      const rankingFixed = '3 rd';
      return { trophyImage, rankingFixed };
    }
    default: {
      const trophyImage = null;
      const rankingFixed = `${ranking} th`;
      return { trophyImage, rankingFixed };
    }
    }
  }

  const displayedBoard =
    scoreBoardDTO && displayAmount < scoreBoardDTO.length
      ? scoreBoardDTO.slice(0, displayAmount)
      : scoreBoardDTO;

  const board = displayedBoard
    ? displayedBoard.map((value, index) => {
      const rankData = selectTrophy(value.ranking);

      return (
        <BoardItem
          isGlobal={isGlobal}
          key={`ScoreItem${index}`}
          index={index}
          tokenId={value.tokenId}
          character={value.thumbnail}
          characterName={value.character}
          ranking={rankData.rankingFixed}
          points={value.score.toString()}
          daypoints={value.dayPoints.toString()}
          itemAddress={value.owner}
          trophy={rankData.trophyImage ? rankData.trophyImage : ''}
          fights={value.fightHistory}
          kickPower={value.kickPower ? value.kickPower.toString() : '0'}
          punchPower={value.punchPower ? value.punchPower.toString() : '0'}
          speed={value.speed ? value.speed.toString() : '0'}
          stamina={value.stamina ? value.stamina.toString() : '0'}
        />
      );
    })
    : null;

  return (
    <div className={styles.BoardContainer}>
      {board}
      {!isTop && (
        <span className={styles.PaginationArrows}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <button
                type="button"
                aria-label="Previous scoreboard"
                className={styles.LeftArrow}
                style={{ opacity: start < 10 ? '.4' : '1' }}
                onClick={() => handleFetch(false)}
              >
                <img src="assets/Components/BuyMode/LeftArrow.png" alt="" />
              </button>
              <span className={styles.CurrentPage}>
                Page {currentPage}
                {isGlobal && ` of ${max}`}
              </span>
              <button
                type="button"
                aria-label="Next scoreboard"
                className={styles.RightArrow}
                style={{
                  opacity:
                    (!isGlobal &&
                      charactersIdOwned &&
                      charactersIdOwned.length >= start + length) ||
                    (isGlobal && currentPage < max)
                      ? '1'
                      : '.4',
                }}
                onClick={() => {
                  if (
                    (!isGlobal &&
                      charactersIdOwned &&
                      charactersIdOwned.length >= start + length) ||
                    (isGlobal && currentPage < max)
                  ) {
                    handleFetch(true);
                  }
                }}
              >
                <img src="assets/Components/BuyMode/RightArrow.png" alt="" />
              </button>
            </>
          )}
        </span>
      )}
    </div>
  );
};

export default ScoreBoard;
