import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from '../../redux/hooks/reduxHooks';
import { APP } from '../../routes';
import styles from './Footer.module.css';
import {
  discordLink,
  privacyPolicyLink,
  telegramLink,
  termsUseLink,
  twitterLink,
} from '../../config';

/**
 * Footer component, depending on the router will render a different style
 */
const Footer: React.FC = () => {
  const { playing } = useAppSelector(state => state.room);

  const location = useLocation();
  return (
    <footer
      className={
        location.pathname === APP.LEADERBOARD
          ? styles.FooterContainerScore
          : styles.FooterContainer
      }
    >
      <div className={styles.LogoContainer}>
        <LinkContainer to={playing ? APP.FIGHT : APP.LANDING}>
          <img className={styles.Logo} src="assets/Logo.png" alt="" />
        </LinkContainer>
        <img
          className={styles.Powered}
          src="assets/Footer/powered.png"
          alt=""
        />
      </div>

      <div className={styles.LinksContainer}>
        <div className={styles.SocialRow}>
          <a href={telegramLink} target="_blank" rel="noreferrer">
            <img src="assets/Footer/telegram.png" alt="" />
          </a>
          <a href={discordLink} target="_blank" rel="noreferrer">
            <img src="assets/Footer/discord.png" alt="" />
          </a>
          <a href={twitterLink} target="_blank" rel="noreferrer">
            <img src="assets/Footer/twitter.png" alt="" />
          </a>
        </div>
        <div className={styles.LinkRow}>
          <a
            href={termsUseLink}
            target="_blank"
            rel="noreferrer"
            className={styles.LegalItem}
          >
            Terms of use
          </a>
          <a
            href={privacyPolicyLink}
            target="_blank"
            rel="noreferrer"
            className={styles.LegalItem}
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
