/**
 * This is the color list the character carousel at landing page and marketplace use for the color background of the cards randomized
 */
export const colorList = [
  '#561f96',
  '#9d349e',
  '#c7437c',
  '#4216d2',
  '#fde6da',
];

/**
 * Amount of fights per id, it match the order of the fetch variable
 * This also needs to match the order and amounts of them, in the way the contract sends them when we fetch.
 * Also this numbers only will change the card text in the website not the functionality of the dApp
 */
export const fightPerId: number[] = [10, 20, 50, 100];

/**
 * This is the dto for the character carousel at the landing page and marketplace
 */
export const MockCharacterCarouselCards = [
  {
    name: 'Alexander The Wonderful',
    description:
      'The Legendary warrior and conqueror´s strength lies in his strategic mind and ability to outmaneuver his foes on the battlefield',
    character: 'assets/Characters/Alexander The Wonderful.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Barther Mayes',
    description:
      'An uncanny ability to stop an opponent and liquidate all threats, Barther leaves no foe unscathed.',
    character: 'assets/Characters/Barther Mayes.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '3',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'BeJon Lames',
    description:
      'A powerful and agile fighter with unmatched speed and strength, BeJon is a formidable opponent for any foe.',
    character: 'assets/Characters/BeJon Lames.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Bill WiggleSpear',
    description:
      'The brute of Avon possesses more than just quick wit and clever insults, his flair and cunning strategy will defeat any enemy.',
    character: 'assets/Characters/Bill WiggleSpear.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Bitalik Vuterin',
    description:
      "A true digital master, no one can stand in Bitalik's way. He dominates foes with quick thinking and strategic gameplay.",
    character: 'assets/Characters/Bitalik Vuterin.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Blake Saul',
    description:
      'A public nuisance, Saul takes the spotlight from his foes with his fierce hands and chiseled jaw.',
    character: 'assets/Characters/Blake Saul.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '3',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Bobert Frowney Jr',
    description:
      'Known for his lightning fast reflexes and powerful punches, Frowney Jr. always gives it his all.',
    character: 'assets/Characters/Bobert Frowney Jr.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Chadwick Hewart',
    description:
      'Chadwick brings exceptional acting skills and combat prowess to the arena where his wisdom can outsmart any adversary.',
    character: 'assets/Characters/Chadwick Hewart.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '1',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Clark Suckerberg',
    description:
      "A skilled fighter with a strategic mind and a knack for creating innovative weapons, don't let his nerdy appearance fool you.",
    character: 'assets/Characters/Clark Suckerberg.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Conair McGruber',
    description:
      'A master of hand to hand combat, Conair McGruber is ready to take on all challengers and emerge victorious.',
    character: 'assets/Characters/Conair McGruber.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '3',
      },
      {
        trait_type: 'Speed',
        value: '5',
      },
      {
        trait_type: 'Stamina',
        value: '2',
      },
    ],
  },
  {
    name: 'Condre Bronje',
    description:
      'A prolific developer, Bronje has wit to outmaneuver his enemy and vanish in time to protect his neck.',
    character: 'assets/Characters/Condre Bronje.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '3',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '5',
      },
      {
        trait_type: 'Stamina',
        value: '2',
      },
    ],
  },
  {
    name: 'Dan Bankruptman',
    description:
      "Faces will receive the wrath of Dan's fists and lives will be brutally decimated in return.",
    character: 'assets/Characters/Dan Bankruptman.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Deonardo LiPaprio',
    description:
      'Once The King of the Metaverse, LiPaprio will leave opponents spinning with his devilish fists and clever strategies.',
    character: 'assets/Characters/Deonardo LiPaprio.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Dick Fixson',
    description:
      'Known for his cunning and determination, Fixon will always find a way to come out on top no matter the odds.',
    character: 'assets/Characters/Dick Fixson.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '2',
      },
    ],
  },
  {
    name: 'Dick Ransom',
    description:
      'A strategic and tenacious competitor, Ransom never backs down and always comes out on top despite facing tough opponents.',
    character: 'assets/Characters/Dick Ransom.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Dim Traper',
    description:
      'Known for his quick reflexes and powerful punches, Dim is always ready to throw down and fight for victory.',
    character: 'assets/Characters/Dim Traper.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Dodger Purr',
    description:
      'Not only does bitcoin have a lighting network, but dodger has lightning fast reflexes. The name befits the holder.',
    character: 'assets/Characters/Dodger Purr.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '5',
      },
      {
        trait_type: 'Stamina',
        value: '1',
      },
    ],
  },
  {
    name: 'Dom Banks',
    description:
      'Banks has seen many adversaries come and go but his might haas never waned in the face of danger.',
    character: 'assets/Characters/Dom Banks.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '3',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Earnie Banders',
    description:
      'Earnie Banders brings his passion for the people to devastate even the strongest opponents.',
    character: 'assets/Characters/Earnie Banders.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '1',
      },
      {
        trait_type: 'Stamina',
        value: '5',
      },
    ],
  },
  {
    name: 'Eyesack Wewton',
    description:
      "Enlightening opponents with slick motion, Wewton's skills on the battlefield are unparalleled.",
    character: 'assets/Characters/Eyesack Wewton.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '2',
      },
      {
        trait_type: 'Kick Power',
        value: '3',
      },
      {
        trait_type: 'Speed',
        value: '5',
      },
      {
        trait_type: 'Stamina',
        value: '5',
      },
    ],
  },
  {
    name: 'Farnold Warzenegger',
    description:
      'With his massive muscles and impressive physique Farnold Warzenegger is a true powerhouse and a force to be reckoned with.',
    character: 'assets/Characters/Farnold Warzenegger.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '1',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Feelon Tusk',
    description:
      'Unpredictability is his middle name; Tusk will appear when his foe least expects and make them see stars',
    character: 'assets/Characters/Feelon Tusk.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Gahmatma Handy',
    description:
      "Handy's ability to combine ahimsa and brutality in a single body makes him untouchable to the witless",
    character: 'assets/Characters/Gahmatma Handy.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '3',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '5',
      },
    ],
  },
  {
    name: 'Geoff Zebos',
    description:
      'Money is power and Geoff has both in abundance. No fight is too stratospheric for him to shy away from',
    character: 'assets/Characters/Geoff Zebos.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '3',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '5',
      },
    ],
  },
  {
    name: 'Harristotle',
    description:
      'Age defying Harristotle is still a formidable fighter, using his quick thinking and agile movements to outwit and overpower his foes.',
    character: 'assets/Characters/Harristotle.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Harry Baynerchuck',
    description:
      "Harry's versatile attacking style turns any foe into mince meat to chuck right back at his friends ",
    character: 'assets/Characters/Harry Baynerchuck.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '3',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '5',
      },
    ],
  },
  {
    name: 'Hobie',
    description:
      'Skilled in understanding his foe, Hobie does not uppercut only, but picks apart those in his path',
    character: 'assets/Characters/Hobie.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Jim KongUn',
    description:
      "Jim may be small, but don't let that fool you. He wears the crown when it's time to take anyone down. ",
    character: 'assets/Characters/Jim KongUn.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '2',
      },
      {
        trait_type: 'Kick Power',
        value: '3',
      },
      {
        trait_type: 'Speed',
        value: '5',
      },
      {
        trait_type: 'Stamina',
        value: '5',
      },
    ],
  },
  {
    name: 'Jim McCoffee',
    description:
      "With caffeine coursing through his veins, McCoffee's ready for any challenge that comes his way.",
    character: 'assets/Characters/Jim McCoffee.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '4',
      },
      {
        trait_type: 'Stamina',
        value: '2',
      },
    ],
  },
  {
    name: 'Jim Snook',
    description:
      "Jim's fighting style is calculated and efficient. He faces his foes with courage bold, and vanquished them with apples so cold.",
    character: 'assets/Characters/Jim Snook.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Johmas Neddison',
    description:
      'Inventor by day and streetfighter by night, little do people know, Neddison can knock the lights out of you - a true man of might. ',
    character: 'assets/Characters/Johmas Neddison.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '2',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '5',
      },
    ],
  },
  {
    name: 'Lalai Darma',
    description:
      'Most humble and wise, Darma makes an opponent feast on their own words or his fist if serenity fails.',
    character: 'assets/Characters/Lalai Darma.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '5',
      },
    ],
  },
  {
    name: 'Lamey Jimon',
    description:
      'When Jimon scents blood, he chases success with all his might and a nemesis will stand little chance.',
    character: 'assets/Characters/Lamey Jimon.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Luce Bree',
    description:
      'With his lightning-fast reflexes and incredible agility, Luce Bree is a force to be reckoned with for any opponent.',
    character: 'assets/Characters/Luce Bree.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '5',
      },
      {
        trait_type: 'Stamina',
        value: '2',
      },
    ],
  },
  {
    name: 'Madimir Pootin',
    description:
      'A leader with a strong will and determination to restore glory on the world stage, Pootin fears no one.',
    character: 'assets/Characters/Madimir Pootin.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '1',
      },
      {
        trait_type: 'Stamina',
        value: '5',
      },
    ],
  },
  {
    name: 'Manny Mevito',
    description:
      'Evil things come in small explosive packages and Mevito has equal measures of both to destroy an enemy.',
    character: 'assets/Characters/Manny Mevito.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '3',
      },
    ],
  },
  {
    name: 'Marak Bobama',
    description:
      'Known for his calm and composed demeanor, master strategist Bobama is able to outmaneuver and outpace his opponents.',
    character: 'assets/Characters/Marak Bobama.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '3',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Marvin Ruther Ting',
    description:
      "Marvin's charisma and powerful speeches match his prowess and cunning on the battlefield which  foes will fear.",
    character: 'assets/Characters/Marvin Ruther Ting.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '3',
      },
    ],
  },
  {
    name: 'Maybraham Dinkum',
    description:
      'Thanks to his imposing stature, the skilled martial artist Maybraham Dinkum is a formidable opponent.',
    character: 'assets/Characters/Maybraham Dinkum.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '3',
      },
    ],
  },
  {
    name: 'Meamu Leaves',
    description:
      'With deadly martial arts skills and a mysterious past, Meamu enters every battle to uncover weaknesses in opponents.',
    character: 'assets/Characters/Meamu Leaves.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '3',
      },
      {
        trait_type: 'Speed',
        value: '4',
      },
      {
        trait_type: 'Stamina',
        value: '3',
      },
    ],
  },
  {
    name: 'Mister Unagi',
    description:
      'A dark arts master, Unagi teaches the power of balance and discipline through Karate and unconventional methods.',
    character: 'assets/Characters/Mister Unagi.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '3',
      },
      {
        trait_type: 'Speed',
        value: '5',
      },
      {
        trait_type: 'Stamina',
        value: '3',
      },
    ],
  },
  {
    name: 'Moe Jiden',
    description:
      'Moe uses his perceived fragility to lure an adversary into his trap before nuking them to oblivion.',
    character: 'assets/Characters/Moe Jiden.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '3',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '3',
      },
    ],
  },
  {
    name: 'Morris Donson',
    description:
      'Political figurehead Donson uses his quick wit and sharp tongue to overpower opponents and dominate in the arena.',
    character: 'assets/Characters/Morris Donson.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '1',
      },
      {
        trait_type: 'Stamina',
        value: '5',
      },
    ],
  },
  {
    name: 'Mr Ween',
    description:
      'The bumbling badass who packs a punch with a spontaneous fighting style, Ween will strike fear into any foe.',
    character: 'assets/Characters/Mr Ween.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '3',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '5',
      },
    ],
  },
  {
    name: 'Nick Besla',
    description:
      'Besla is a brilliant inventor who harnesses the power of shock to gain the upper hand with his opponents.',
    character: 'assets/Characters/Nick Besla.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '5',
      },
      {
        trait_type: 'Stamina',
        value: '1',
      },
    ],
  },
  {
    name: 'Nick Raylor',
    description:
      'Raylor is a businessman by day and a web3-warrior by night, ready to battle for dominance against all odds.',
    character: 'assets/Characters/Nick Raylor.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Park Mooban',
    description:
      'An esteemed businessman, and now a fierce fighter, Park takes on all challengers with quick wit and a sharp mind.',
    character: 'assets/Characters/Park Mooban.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Perome Jowell',
    description:
      'Known for his cunning, Jowell will reserve no policy to send his foe into depression.',
    character: 'assets/Characters/Perome Jowell.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Phil Bates',
    description:
      'Using his technical knowledge to gain an edge in combat, Phil is a true champion and a force to be reckoned with.',
    character: 'assets/Characters/Phil Bates.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Pi Xinjing',
    description:
      'The supreme powerhouse, Pi, enters the cauldron with an iron fist and a will to conquer all challengers.',
    character: 'assets/Characters/Pi Xinjing.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '3',
      },
    ],
  },
  {
    name: 'Priss Frock',
    description:
      'As deadly as he is hilarious, Frock is all business with his impressive strength and incredible speed.',
    character: 'assets/Characters/Priss Frock.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '3',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '5',
      },
      {
        trait_type: 'Stamina',
        value: '3',
      },
    ],
  },
  {
    name: 'Pritchard Mart',
    description:
      'Tech guru Mart enters the battlefield with a will to prove knowledge is the ultimate power.',
    character: 'assets/Characters/Pritchard Mart.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '2',
      },
    ],
  },
  {
    name: 'Ralbert Ninestine',
    description:
      "With his impressive intellect, Ninestine is able to predict opponent's' movements and strike before they know what hit them.",
    character: 'assets/Characters/Ralbert Ninestine.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '3',
      },
      {
        trait_type: 'Kick Power',
        value: '3',
      },
      {
        trait_type: 'Speed',
        value: '4',
      },
      {
        trait_type: 'Stamina',
        value: '5',
      },
    ],
  },
  {
    name: 'Raval Navikant',
    description:
      "Raval's sharp wit and strategic mind match his electric fists when it comes to dissecting a foe.",
    character: 'assets/Characters/Raval Navikant.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Ray Buvara',
    description:
      'Ray Buvara is a master of hand-to-hand combat, with a deadly combination of speed, strength, and determination.',
    character: 'assets/Characters/Ray Buvara.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '3',
      },
    ],
  },
  {
    name: 'Reeve Bobs',
    description:
      'Visionary Bobs enters the fight game to revolutionize ass-whoopings with his quick thinking and innovative moves.',
    character: 'assets/Characters/Reeve Bobs.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '4',
      },
      {
        trait_type: 'Stamina',
        value: '1',
      },
    ],
  },
  {
    name: 'Reeve Bozniak',
    description:
      "Bozniak's tech genius coupled with his astonishing eye for exploiting weaknesses renders him a match to be reckoned with.",
    character: 'assets/Characters/Reeve Bozniak.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '1',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Rogan Saul',
    description:
      "Followers aren't all this online sensation has, Saul will dazzle opponents with his speed and power.",
    character: 'assets/Characters/Rogan Saul.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '3',
      },
    ],
  },
  {
    name: 'Ronald Dump',
    description:
      "Ronald bows down to no one and uses his power and deceit to make an opponent's face break again.",
    character: 'assets/Characters/Ronald Dump.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '3',
      },
    ],
  },
  {
    name: 'Ronnie Pepp',
    description:
      'Armed with an abundance of tricks, Pepp is ready to take down any challenger in his quest for glory.',
    character: 'assets/Characters/Ronnie Pepp.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '3',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Rustin Jun',
    description:
      'A Web3 prodigy, Jun enters the fight to dominate the competition with his unshakable determination.',
    character: 'assets/Characters/Rustin Jun.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '3',
      },
      {
        trait_type: 'Speed',
        value: '5',
      },
      {
        trait_type: 'Stamina',
        value: '3',
      },
    ],
  },
  {
    name: 'Rustin Toolow',
    description:
      "Don't let the peaceful, diplomatic image fool you. Rustin is a formidable opponent, always striving to be the best.",
    character: 'assets/Characters/Rustin Toolow.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Ryan Barnstrong',
    description:
      'Ryan uses his high stock and prowess to regulate any opponent threat and take them down on the spot.',
    character: 'assets/Characters/Ryan Barnstrong.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '3',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Satoshi Nakamoto',
    description:
      'The mysterious genius behind Bitcoin enters the fight ready to take down any opponent in the name of decentralization.',
    character: 'assets/Characters/Satoshi Nakamoto.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '3',
      },
      {
        trait_type: 'Speed',
        value: '5',
      },
      {
        trait_type: 'Stamina',
        value: '2',
      },
    ],
  },
  {
    name: 'SeeZed',
    description:
      'Looks can be deceiving - SeeZed uses his masterful mind to connect with the perfect weakness in his opponent.',
    character: 'assets/Characters/SeeZed.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '2',
      },
      {
        trait_type: 'Kick Power',
        value: '3',
      },
      {
        trait_type: 'Speed',
        value: '5',
      },
      {
        trait_type: 'Stamina',
        value: '5',
      },
    ],
  },
  {
    name: 'Soren Muffet',
    description:
      'Ruthless in every walk of life, Soren uses his strategic mind to outmaneuver opponents and always lasts the distance.',
    character: 'assets/Characters/Soren Muffet.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '1',
      },
      {
        trait_type: 'Stamina',
        value: '5',
      },
    ],
  },
  {
    name: 'Spike Bryson',
    description:
      'Spike is one of the most iconic figures in the fight game. Steel fists and a solid chin will take him to glory again.',
    character: 'assets/Characters/Spike Bryson.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '2',
      },
      {
        trait_type: 'Speed',
        value: '5',
      },
      {
        trait_type: 'Stamina',
        value: '3',
      },
    ],
  },
  {
    name: 'The Poke',
    description:
      'Spreading whoop-ass throughout the world, Poke uses his determination and spiritual strength to overcome his opponents.',
    character: 'assets/Characters/The Poke.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '5',
      },
    ],
  },
  {
    name: 'Trezor',
    description:
      'Despite his tough exterior, Trezor heart of gold will fight for the greater good. He is a skilled strategist and never backs down.',
    character: 'assets/Characters/Trezor.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '1',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Twinkletoss',
    description:
      'With his imposing physique and incredible strength, Twinkletoss is able to deliver devastating blows to his opponents.',
    character: 'assets/Characters/Twinkletoss.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '2',
      },
      {
        trait_type: 'Speed',
        value: '4',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Will Glyph',
    description:
      'Glyph is a warrior, born and raised. On the battlefield is where he spent most of his days. Powerful punches await any opponent.',
    character: 'assets/Characters/Will Glyph.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Wooly Wee',
    description:
      'Wooly Wee is a rising star in the fight game, known for his lightning fast reflexes and powerful punch combinations.',
    character: 'assets/Characters/Wooly Wee.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Yeeter Pith',
    description:
      'Known for his powerful hooks and relentless determination. Despite suffering numerous setbacks, Pith always comes back stronger.',
    character: 'assets/Characters/Yeeter Pith.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '4',
      },
      {
        trait_type: 'Speed',
        value: '3',
      },
      {
        trait_type: 'Stamina',
        value: '3',
      },
    ],
  },
  {
    name: 'Zach Horsey',
    description:
      'When squaring off with a foe, Horsey uses a forceful style of combat and trickery to send them flying.',
    character: 'assets/Characters/Zach Horsey.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '4',
      },
      {
        trait_type: 'Kick Power',
        value: '5',
      },
      {
        trait_type: 'Speed',
        value: '2',
      },
      {
        trait_type: 'Stamina',
        value: '4',
      },
    ],
  },
  {
    name: 'Zolodymyr Velenskyy',
    description:
      'With a quick wit and ferocious fighting spirit, Zolodymyr is always ready to take on any enemy who stands in his way.',
    character: 'assets/Characters/Zolodymyr Velenskyy.png',
    skills: [
      {
        trait_type: 'Background',
        value: 'Chun Lee Blue',
      },
      {
        trait_type: 'Character',
        value: 'Soren Muffet',
      },
      {
        trait_type: 'Facewear',
        value: 'Lazer Eyes',
      },
      {
        trait_type: 'Headwear',
        value: 'Wizard',
      },
      {
        trait_type: 'Hands',
        value: 'Claw Red',
      },
      {
        trait_type: 'Punch Power',
        value: '5',
      },
      {
        trait_type: 'Kick Power',
        value: '3',
      },
      {
        trait_type: 'Speed',
        value: '4',
      },
      {
        trait_type: 'Stamina',
        value: '3',
      },
    ],
  },
];
