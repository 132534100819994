import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BigNumber, ethers } from 'ethers';
import axios from 'axios';

import {
  BackgroundDTO,
  AudioCardDTO,
  AudioDTO,
  BackgroundCardDTO,
  MetadataDTO,
  PassesIds,
} from '../../../lib/types';
import { contractAddresses } from '../../../config';
import charactersAbi from '../../../abi/Characters.json';
import backgroundsAbi from '../../../abi/Background.json';
import audioTauntAbi from '../../../abi/AudioTaunt.json';
import passAbi from '../../../abi/Pass.json';
import { setUserFetched } from '../connectReducer/connectReducer';

/**
 * @param {string} uri arweave incorrect URI that opensea can fetch but not this frontend
 * @returns {string} corrected URI to fetch from arweave
 */
export function fixArweaveURI(uri: string) {
  return `https://arweave.net/${uri.substring(5)}`;
}

const emptyDto = [
  {
    name: '',
    description: '',
    image: '',
    imgNoBackground: '',
    attributes: [
      { trait_type: '', value: '0' },
      { trait_type: '', value: '0' },
    ],
  },
];

// Dto for characters when their url has a hidden.json in it, to display unrevealed data
const hiddenDto = {
  name: '',
  description: 'Character is hidden, wait until reveal.',
  image: 'assets/Characters/hidden.gif',
  imgNoBackground: 'assets/Characters/hidden.gif',
  attributes: [
    { trait_type: '', value: '' },
    { trait_type: '', value: 'Hidden' }, // this name triggers the arweave fix to not be used
    { trait_type: '', value: '' },
    { trait_type: '', value: '' },
    { trait_type: '', value: '' },
    { trait_type: '', value: '0' },
    { trait_type: '', value: '0' },
    { trait_type: '', value: '0' },
    { trait_type: '', value: '0' },
  ],
};

// Dto default, when the fetch.json() fails with 400
const defaultCharacterDto = {
  name: '',
  description: '',
  image: '',
  imgNoBackground: '',
  attributes: [
    { trait_type: '', value: '' },
    { trait_type: '', value: 'Loading..' },
    { trait_type: '', value: '' },
    { trait_type: '', value: '' },
    { trait_type: '', value: '' },
    { trait_type: '', value: '0' },
    { trait_type: '', value: '0' },
    { trait_type: '', value: '0' },
    { trait_type: '', value: '0' },
  ],
};

/**
 * @param {string[] }tokenURIs array of token uris
 * @param {number} mode type of asset. 0 for characters | 1 for backgrounds | 2 for audiotaunts
 * @returns an array of the JSON metadatas from the tokenURIs, fixing the ARWEAVE uri originally in opensea format
 */
const fetchJSON = async (tokenURIs: string[], mode: number) => {
  switch (mode) {
  case 0: {
    const dto: MetadataDTO[] = await Promise.all(
      tokenURIs.map(async url => {
        if (url.includes('hidden.json')) {
          // if the url ends in hidden.json, use default hidden data.
          return hiddenDto;
        }
        const resp = await fetch(`${url}`);
        const jsonResp = await resp.json();
        if (jsonResp.statusCode === 400) {
          // if the json fails
          return defaultCharacterDto;
        }
        return jsonResp;
      }),
    );

    const fixedDto = dto.map(element => {
      element.name =
          element.attributes && element.attributes[1]
            ? element.attributes[1].value
            : element.name; // if the attribute[1] its setted, use that to build the character card name
      element.image =
          element.image && element.name !== 'Hidden' // if hidden, we dont need to fix the areweave uri
            ? fixArweaveURI(element.image)
            : element.image;
      return element;
    });
    return fixedDto;
  }
  case 1: {
    const dto: MetadataDTO[] = await Promise.all(
      tokenURIs.map(async url => {
        const fixedUri = fixArweaveURI(url);
        const resp = await fetch(`${fixedUri}`);
        const jsonResp = await resp.json();
        if (jsonResp.statusCode === 400) {
          // if the json fails
          return emptyDto;
        }
        return jsonResp;
      }),
    );

    const fixedDto = dto.map(element => {
      element.image = element.image
        ? fixArweaveURI(element.image)
        : undefined;
      return element;
    });
    return fixedDto;
  }
  case 2: {
    const dto: MetadataDTO[] = await Promise.all(
      tokenURIs.map(async url => {
        const fixedUri = fixArweaveURI(url);
        const resp = await fetch(`${fixedUri}`);
        const jsonResp = await resp.json();
        if (jsonResp.statusCode === 400) {
          // if the json fails
          return emptyDto;
        }
        return jsonResp;
      }),
    );

    const fixedDto = dto.map(element => {
      element.image = element.image
        ? fixArweaveURI(element.image)
        : undefined;
      element.audio = element.audio
        ? fixArweaveURI(element.audio)
        : undefined;
      return element;
    });
    return fixedDto;
  }
  default: {
    return emptyDto;
  }
  }
};

/**
 * Fetches the passes metadata
 * @param {number[]} tokenIds array of tokenIds to fetch
 * @param {number} passId pass id to fetch the metadata. Based on the passId from the contract. Example: 0 for the first type of pass of the ERC1155
 * @returns the metadata for passes
 */
const fetchPassesMetadata = async (tokenIds: number[], passId: number) => {
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    timeout: 5000,
    headers: {
      crossDomain: true,
      accept: 'application/json',
    },
  });

  const metadataArr = await Promise.all(
    tokenIds.map(async element => {
      const params = {
        passId,
        tokenId: element,
      };

      const { data } = await instance.get(
        `passes/metadata/${passId}/${element}`,
        {
          params,
        },
      );

      const dto = {
        name: data.name,
        attributes: data.attributes,
        description: data.description,
      };

      return dto;
    }),
  );

  return metadataArr;
};

/**
 * Fetch the nfts from the user
 * All params are passed wrapped inside an object as properties
 * @param contractAddress address of the contract to instance
 * @param abi abi of the contract to instance
 * @param mode type of asset to fetch. 0 for characters | 1 for backgrounds | 2 for audiotaunts | 3 for passes
 */
export const fetchNFT = createAsyncThunk(
  'fetchNFT',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action: any, thunkAPI: any) => {
    try {
      const { provider, address } = thunkAPI.getState().connect;

      const contract = new ethers.Contract(
        action.contractAddress,
        action.abi,
        provider,
      );

      // if the wallet is from an extra item, this will be an array with the amount for all the types. Ej: 0 0 0 1 0
      const wallet: ethers.BigNumber[] = await contract.walletOfOwner(address);

      const ownedWallet: number[] = [];
      if (action.mode > 0 && action.mode < 3) {
        wallet.forEach(value => {
          const numberAmount = value.toNumber();
          if (numberAmount > 0) ownedWallet.push(numberAmount);
        });
      }
      const extraIds: number[] = [];
      if (action.mode === 2 || action.mode === 1) {
        wallet.forEach((element, index) => {
          const amountPerType = element.toNumber();

          if (amountPerType > 0) extraIds.push(index);
        });
      }
      const tokenIds: number[] =
        action.mode === 2 || action.mode === 1
          ? extraIds
          : wallet.map(value => value.toNumber());

      const baseURI: string = await contract.baseURI();

      const tokenURIs: string[] | null =
        tokenIds.length > 0 && action.mode < 3
          ? await Promise.all(
            tokenIds.map(async value => {
              const URI =
                  action.mode === 1 || action.mode === 2
                    ? baseURI.concat(`${value.toString()}.json`)
                    : baseURI.concat(value.toString());
              return URI;
            }),
          )
          : null;

      const metadata: MetadataDTO[] = tokenURIs
        ? await fetchJSON(tokenURIs, action.mode)
        : await fetchPassesMetadata(tokenIds, action.passId);

      const totalSupply: BigNumber | null =
        action.mode === 0 ? await contract.totalSupply() : null;
      return {
        mode: action.mode,
        tokenIds,
        passId: action.passId, // metadata
        totalSupply,
        metadata,
        walletAmounts: ownedWallet,
      };
    } catch (error) {
      console.log('Error fetching NFT', error);
      throw error;
    }
  },
);

/**
 * It fetches the available extras the contracts can sell
 * All params are passed wrapped inside an object as properties
 * @param contractAddress address of the contract to instance
 * @param abi abi of the contract to instance
 * @param isBackground type of asset to fetch. true for backgrounds. false for audiotaunts
 */
export const initExtra = createAsyncThunk(
  'initExtra',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action: any, thunkAPI: any) => {
    try {
      const { provider } = thunkAPI.getState().connect;
      const { backgroundsPrices, audiosPrices } = thunkAPI.getState().nft;

      const contract = new ethers.Contract(
        action.contractAddress,
        action.abi,
        provider,
      );

      const typesAmount = await contract.getAmountOfTypes();
      const baseURI: string = await contract.baseURI();
      const dto = [];
      for (let i = 0; i < typesAmount; i += 1) {
        const uri = baseURI.concat(`${i}.json`);
        dto.push(uri);
      }

      const fetch: MetadataDTO[] | undefined = await fetchJSON(
        dto,
        action.isBackground ? 1 : 2,
      );

      if (action.isBackground) {
        const dataBackground: BackgroundCardDTO[] | undefined = fetch
          ? fetch.map((element, index) => {
            const fixedObject: BackgroundCardDTO | undefined = {
              name:
                  element.attributes && element.attributes[0]
                    ? element.attributes[0].value
                    : '',
              description: element.description,
              background: element.image || '',
              price: backgroundsPrices[index],
              id: index,
            };
            return fixedObject;
          })
          : undefined;

        return {
          dataBackground,
          isBackground: action.isBackground,
        };
      }
      const dataAudio: AudioCardDTO[] | undefined = fetch
        ? fetch.map((element, index) => ({
          title: element.name,
          price: audiosPrices[index],
          id: index,
          characterName:
              element.attributes && element.attributes[1]
                ? element.attributes[1].value
                : element.name,
          audioTrack: element.audio || '',
        }))
        : undefined;
      return {
        dataAudio,
        isBackground: action.isBackground,
      };
    } catch (error) {
      console.log('Error initializing Extras', error);
      throw error;
    }
  },
);

/**
 * Fetches the prices from the backend endpoint
 */
export const fetchPrices = createAsyncThunk(
  'fetchPrices',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action, thunkAPI: any) => {
    try {
      const { axiosInstance } = thunkAPI.getState().api;

      const priceDTO = await axiosInstance.get('blockchain/prices');

      const charactersPrice = Number(
        ethers.utils.formatEther(priceDTO.data.CHARACTER_CONTRACT),
      );
      const audiosPrices = priceDTO.data.EXTRAS_AUDIOS_CONTRACT.map(
        (element: string) => Number(ethers.utils.formatEther(element)),
      );
      const backgroundsPrices = priceDTO.data.EXTRAS_BACKGROUND_CONTRACT.map(
        (element: string) => Number(ethers.utils.formatEther(element)),
      );
      const passesPrices = [
        Number(ethers.utils.formatEther(priceDTO.data.PASS10_CONTRACT)),
        Number(ethers.utils.formatEther(priceDTO.data.PASS20_CONTRACT)),
        Number(ethers.utils.formatEther(priceDTO.data.PASS50_CONTRACT)),
        Number(ethers.utils.formatEther(priceDTO.data.PASS100_CONTRACT)),
      ];

      return { charactersPrice, audiosPrices, backgroundsPrices, passesPrices };
    } catch (error) {
      console.log('Error fetching prices', error);
      Promise.resolve(setTimeout(() => {}, 1000));
      thunkAPI.dispatch(fetchPrices());
      throw error;
    }
  },
);

/**
 * General thunk to dispatch all the required fetches in one call.
 * This thunk fetch the user nfts and inits the assets to sell in the marketplace
 * @param {boolean} wihoutMarket optional param to cancel the fetch fo the audios and backgrounds for sell to reduce loadtime
 */
export const initNFT = createAsyncThunk(
  'initNFT',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action: { withoutMarket?: boolean }, thunkAPI: any) => {
    try {
      await thunkAPI.dispatch(fetchPrices());

      await thunkAPI.dispatch(
        fetchNFT({
          mode: 0,
          abi: charactersAbi,
          contractAddress: contractAddresses.Characters,
        }),
      );

      await thunkAPI.dispatch(
        fetchNFT({
          mode: 1,
          abi: backgroundsAbi,
          contractAddress: contractAddresses.Background,
        }),
      );

      await thunkAPI.dispatch(
        fetchNFT({
          mode: 2,
          abi: audioTauntAbi,
          contractAddress: contractAddresses.AudioTaunt,
        }),
      );

      contractAddresses.Passes.forEach(async (value, index) => {
        await thunkAPI.dispatch(
          fetchNFT({
            mode: 3,
            abi: passAbi,
            contractAddress: value.address,
            passId: index,
          }),
        );
      });

      if (!action.withoutMarket) {
        await thunkAPI.dispatch(
          initExtra({
            contractAddress: contractAddresses.AudioTaunt,
            abi: audioTauntAbi,
            isBackground: false,
          }),
        );

        await thunkAPI.dispatch(
          initExtra({
            contractAddress: contractAddresses.Background,
            abi: backgroundsAbi,
            isBackground: true,
          }),
        );
      }

      thunkAPI.dispatch(setUserFetched({ set: true }));
    } catch (error) {
      console.log('Error initializing NFTs', error);
      throw error;
    }
  },
);

/**
 * Fetch the characters supply
 */
export const fetchCharacterSupply = createAsyncThunk(
  'fetchCharacterSupply',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action, thunkAPI: any) => {
    try {
      const { provider } = thunkAPI.getState().connect;

      const contract = new ethers.Contract(
        contractAddresses.Characters,
        charactersAbi,
        provider,
      );

      const totalSupply: BigNumber = await contract.totalSupply();
      return {
        totalSupply,
      };
    } catch (error) {
      console.log('Error fetching characters supply', error);
      Promise.resolve(setTimeout(() => {}, 1000));
      thunkAPI.dispatch(fetchCharacterSupply());
      throw error;
    }
  },
);

const nftSlice = createSlice({
  name: 'nftReducer',
  initialState: {
    charactersIdOwned: null as number[] | null,
    charactersMetadata: [{}] as MetadataDTO[] | undefined,
    charactersSupply: null as number | null,
    backgroundsIdOwned: null as number[] | null,
    backgroundsAmountOwned: null as number[] | null,
    backgroundsMetadata: [{}] as MetadataDTO[] | undefined,
    backgroundsToSell: [{}] as BackgroundDTO[] | undefined,
    audiosIdOwned: null as number[] | null,
    audiosAmountOwned: null as number[] | null,
    audiosMetadata: [{}] as MetadataDTO[] | undefined,
    audiosToSell: [{}] as AudioDTO[] | undefined,
    passIdOwned: [[], [], [], []] as PassesIds[],
    passMetadata: [[], [], [], []] as MetadataDTO[][],
    charactersPrice: 0,
    audiosPrices: [0],
    backgroundsPrices: [0],
    passesPrices: [0],
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchNFT.fulfilled, (state, action) => {
        const supplyNumber = action.payload.totalSupply
          ? action.payload.totalSupply.toNumber()
          : null;
        switch (action.payload.mode) {
        case 0: {
          // characters
          state.charactersIdOwned = action.payload.tokenIds;
          state.charactersMetadata = action.payload.metadata;
          state.charactersSupply = supplyNumber;
          break;
        }
        case 1: {
          // backgrounds
          state.backgroundsIdOwned = action.payload.tokenIds;
          state.backgroundsMetadata = action.payload.metadata;
          state.backgroundsAmountOwned = action.payload.walletAmounts;
          break;
        }
        case 2: {
          // audios
          state.audiosIdOwned = action.payload.tokenIds;
          state.audiosMetadata = action.payload.metadata;
          state.audiosAmountOwned = action.payload.walletAmounts;
          break;
        }
        case 3: {
          // passes
          state.passIdOwned[action.payload.passId] = action.payload.tokenIds;
          state.passMetadata[action.payload.passId] = action.payload.metadata
            ? action.payload.metadata
            : [];
          break;
        }
        default: {
          break;
        }
        }
      })
      .addCase(initExtra.fulfilled, (state, action) => {
        if (action.payload.isBackground) {
          state.backgroundsToSell = action.payload.dataBackground;
        } else {
          state.audiosToSell = action.payload.dataAudio;
        }
      })
      .addCase(fetchCharacterSupply.fulfilled, (state, action) => {
        state.charactersSupply = action.payload.totalSupply.toNumber();
      })
      .addCase(fetchPrices.fulfilled, (state, action) => {
        state.charactersPrice = action.payload.charactersPrice;
        state.audiosPrices = action.payload.audiosPrices;
        state.backgroundsPrices = action.payload.backgroundsPrices;
        state.passesPrices = action.payload.passesPrices;
      });
  },
});

export const nftReducer = nftSlice.reducer;
