import React, { Suspense } from 'react';
import styles from './GameHome.module.css';
import Loader from '../../../components/Loader/Loader';

const GameHome: React.FC = () => {
  const StagesComponent = React.lazy(
    () => import('../GameLauncher/GameLauncher'),
  );
  return (
    <section className={styles.GameHomeContainer}>
      <div className={styles.GameContainer}>
        <Suspense fallback={<Loader forScreen />}>
          <StagesComponent />
        </Suspense>
      </div>

      <div className={styles.ControlsContainer}>

        <h5>GAME CONTROLS</h5>

        <div className={styles.ControlItems}>

          <div className={styles.ControlItem}>
            <div><span>Left/Right</span></div>
            <div><em>&#8680;</em></div>
            <div>Move</div>
          </div>

          <div className={styles.ControlItem}>
            <div><span>Up</span></div>
            <div><em>&#8680;</em></div>
            <div>Jump</div>
          </div>

          <div className={styles.ControlItem}>
            <div><span>Z</span></div>
            <div><em>&#8680;</em></div>
            <div>Punch</div>
          </div>

          <div className={styles.ControlItem}>
            <div><span>X</span></div>
            <div><em>&#8680;</em></div>
            <div>Kick</div>
          </div>

          <div className={styles.ControlItem}>
            <div><span>V</span></div>
            <div><em>&#8680;</em></div>
            <div>Block</div>
          </div>

          <div className={styles.ControlItem}>
            <div><span>Down</span></div>
            <div><em>&#8680;</em></div>
            <div>Crouch</div>
          </div>

          <div className={styles.ControlItem}>
            <div><span>Back</span> &#62; <span>Up</span> &#62; <span>Forward</span></div>
            <div><em>&#8680;</em></div>
            <div>Fireball</div>
          </div>

          <div className={styles.ControlItem}>
            <div><span>Q</span> &#62; <span>E</span> &#62; <span>Q</span> &#62; <span>R</span></div>
            <div><em>&#8680;</em></div>
            <div>Special 1</div>
          </div>

          <div className={styles.ControlItem}>
            <div><span>Q</span> &#62; <span>R</span> &#62; <span>E</span> &#62; <span>Q</span></div>
            <div><em>&#8680;</em></div>
            <div>Special 2</div>
          </div>

        </div>

      </div>
    </section>
  );
};

export default GameHome;
