import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';

import { toggleBuyMode } from '../redux/reducers/marketReducer/marketReducer';
import { AudioDTO, BackgroundDTO } from './types';

/**
 * Method to handle the purchase of an asset. Passing the data through the market reducer so the buy screen can display it
 * @param amount amount of the asset to buy
 * @param mode  Its used to choose between wich type of asset the user is trying to buy.
 * 0 for characters | 1 for backgrounds | 2 for audiotaunts | 3 for passes
 * @param dispatch dispatch reference to trigger the reducer action
 * @param backgroundData dto for the background if choosed
 * @param audioData dto for the background if choosed
 * @param selectedPassId id of the pass if choosed
 * @param selectedExtraId id of the extra if choosed
 */
export const handlePurchase = (
  amount: number,
  mode: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<any, undefined, AnyAction> & Dispatch<AnyAction>,
  backgroundData: BackgroundDTO | undefined,
  audioData: AudioDTO | undefined,
  selectedPassId: number | undefined,
  selectedExtraId: number | undefined,
) => {
  const id = mode === 3 ? selectedPassId : selectedExtraId;
  dispatch(
    toggleBuyMode({
      amount,
      mode,
      id,
      backgroundData,
      audioData,
    }),
  );
};
