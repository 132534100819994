import { createSlice } from '@reduxjs/toolkit';
import { AudioDTO, BackgroundDTO } from '../../../lib/types';

export type State = {
  buyMode: boolean;
  mode: number;
  amount: number;
  selectedPassId: number | null;
  selectedExtraId: number | null;
  audioData: AudioDTO | null;
  backgroundData: BackgroundDTO | null;
};

/**
 * The initial connect state will fetch all the available data from the cache of the browser
 * To redirect the user to the correct buy screen of the last asset
 */
const initialConnectState: State = {
  mode: window.localStorage.getItem('buyMode')
    ? Number(window.localStorage.getItem('buyMode'))
    : 0,
  buyMode: false,
  amount: 0,
  selectedPassId:
    Number(window.localStorage.getItem('buyMode')) === 3
      ? Number(window.localStorage.getItem('passId'))
      : null,
  selectedExtraId:
    Number(window.localStorage.getItem('buyMode')) !== 3
      ? Number(window.localStorage.getItem('selectedExtraId'))
      : null,
  audioData:
    Number(window.localStorage.getItem('buyMode')) === 2
      ? ({
        title: window.localStorage.getItem('audioTitle'),
        price: window.localStorage.getItem('audioPrice')
          ? window.localStorage.getItem('audioPrice')
          : null,
        characterName: window.localStorage.getItem('audioCharacterName'),
        audioTrack: window.localStorage.getItem('audioTrack'),
        viewMode: !!window.localStorage.getItem('audioViewMode'),
      } as AudioDTO)
      : null,
  backgroundData:
    Number(window.localStorage.getItem('buyMode')) === 1
      ? ({
        name: window.localStorage.getItem('backgroundName'),
        price: window.localStorage.getItem('backgroundPrice')
          ? window.localStorage.getItem('backgroundPrice')
          : null,
        description: window.localStorage.getItem('backgroundDescription'),
        background: window.localStorage.getItem('backgroundBackground'),
      } as BackgroundDTO)
      : null,
};

const marketSlice = createSlice({
  name: 'marketReducer',
  initialState: initialConnectState,
  reducers: {
    /**
     * It will toggle the user to the buy screen. Using the passed data to render the correct asset
     * All params are passed wrapped into an object as properties.
     * {
     * @param {BackgroundDTO} backgroundData dto for the background card to be displayed
     * @param {number} id token id of the asset
     * @param {AudioDTO} audioData dto for the audio card to be displayed
     * @param {number} mode mode of the buy screen to display.
     * 0 for characters | 1 for backgrounds | 2 for audiotaunts | 3 for passes
     * @param {number} amount amount of assets to buy
     * }
     */
    toggleBuyMode: (state, action) => {
      state.buyMode = true;
      switch (action.payload.mode) {
      case 1: {
        state.backgroundData = action.payload.backgroundData;
        state.selectedExtraId = action.payload.id;
        break;
      }
      case 2: {
        state.audioData = action.payload.audioData;
        state.selectedExtraId = action.payload.id;
        break;
      }
      case 3: {
        state.selectedPassId = action.payload.id;
        break;
      }
      default:
        break;
      }
      state.mode = action.payload.mode;
      state.amount = action.payload.amount;
    },
  },
});

export const marketReducer = marketSlice.reducer;
export const { toggleBuyMode } = marketSlice.actions;
