import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/esm/Nav';
import { LinkContainer } from 'react-router-bootstrap';

import { CarouselWrap, NotificationModal } from '../../../components';
import CustomSelector from '../../../components/CustomSelector/CustomSelector';
import Loader from '../../../components/Loader/Loader';
import { openseaCollection } from '../../../config';

import { AudioDTO, BackgroundDTO, CharacterDTO } from '../../../lib/types';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../redux/hooks/reduxHooks';
import { APP } from '../../../routes';
import styles from './MyHome.module.css';
import { handlePurchase } from '../../../lib/utils';

const MyNFTsHome: React.FC = () => {
  const { address, userFetched } = useAppSelector(state => state.connect);
  const {
    charactersIdOwned,
    charactersMetadata,
    backgroundsIdOwned,
    backgroundsMetadata,
    backgroundsAmountOwned,
    audiosIdOwned,
    audiosMetadata,
    audiosAmountOwned,
  } = useAppSelector(state => state.nft);

  const { passIdOwned } = useAppSelector(state => state.nft);
  const [selected, setSelected] = useState(''); // Selected character name to filter audios
  const [characterDTO, setCharacterDTO] = useState<CharacterDTO[]>([]);
  const [backgroundDTO, setBackgroundDTO] = useState<BackgroundDTO[]>([]);
  const [audiosDTO, setAudiosDTO] = useState<AudioDTO[]>([]);
  const [totalPasses, setTotalPasses] = useState(0); // Total owned passses
  const [nameList, setNameList] = useState<string[]>([]); // Character name list to display in the selector among owned ones
  const dispatch = useAppDispatch();

  /** Format the metadata to build the card mappings */
  useEffect(() => {
    if (address) {
      const fixedCharacterDTO: CharacterDTO[] | null =
        charactersIdOwned && charactersMetadata
          ? charactersIdOwned.map((element, index) => ({
            name: charactersMetadata[index].name,
            description: charactersMetadata[index].description,
            character: charactersMetadata[index].imgNoBackground || '',
            tokenId: element.toString(),
            skills: charactersMetadata[index].attributes,
          }))
          : null;

      setCharacterDTO(fixedCharacterDTO || []);

      const fixedBackgroundDTO: BackgroundDTO[] | null =
        backgroundsMetadata && backgroundsIdOwned && backgroundsAmountOwned
          ? backgroundsMetadata.map((element, index) => ({
            name: element.attributes[0].value,
            description: element.description,
            background: element.image || '',
            amount: backgroundsAmountOwned[index],
            id: backgroundsIdOwned[index],
          }))
          : null;
      setBackgroundDTO(fixedBackgroundDTO || []);
      const fixedAudiosDTO: AudioDTO[] | null = [];

      if (audiosMetadata && audiosIdOwned && audiosAmountOwned) {
        audiosMetadata.forEach((element, index) => {
          if (element.attributes[1].value === undefined) return;
          const newList = nameList;
          const alreadyInside = newList.find(
            name => name === element.attributes[1].value,
          );
          if (alreadyInside === undefined) newList.push(element.attributes[1].value);
          setNameList(newList);

          const dto = {
            audioTrack: element.audio || '',
            title: element.name,
            characterName: element.attributes[1].value,
            id: audiosIdOwned[index],
            viewMode: true,
            amount: audiosAmountOwned[index],
          };
          if (selected.length === 0) {
            fixedAudiosDTO.push(dto);
          } else if (element.attributes[1].value === selected) {
            fixedAudiosDTO.push(dto);
          }
        });
      }

      setAudiosDTO(fixedAudiosDTO || []);

      setTotalPasses(
        passIdOwned[0].length +
          passIdOwned[1].length +
          passIdOwned[2].length +
          passIdOwned[3].length,
      );
    }
  }, [
    nameList,
    selected,
    charactersIdOwned,
    charactersMetadata,
    address,
    passIdOwned,
    audiosIdOwned,
    audiosMetadata,
    backgroundsMetadata,
    backgroundsIdOwned,
    audiosAmountOwned,
    backgroundsAmountOwned,
  ]);

  const handleSelector = (value: string) => {
    setSelected(value);
  };

  return (
    <section className={styles.HomeContainer}>
      {address ? (
        userFetched ? (
          <>
            <span className={styles.Title}>My Fighters</span>
            {characterDTO.length > 0 ? (
              <CarouselWrap dots mode={0} characterDTO={characterDTO} />
            ) : (
              <span className={styles.BuyMessage}>
                You Have 0 Fighters
                {process.env.REACT_APP_CHARACTERS_ACTIVE === 'true' && (
                  <LinkContainer to={APP.BUY} className={styles.BuyButton}>
                    <Nav.Link
                      onClick={() =>
                        handlePurchase(
                          1,
                          0,
                          dispatch,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                        )
                      }
                    >
                      Mint A Random Fighter
                    </Nav.Link>
                  </LinkContainer>
                )}
                <a
                  href={
                    openseaCollection.collectionBaseUri + openseaCollection.name
                  }
                  target="_blank"
                  rel="noreferrer"
                  className={styles.BuyButton}
                >
                  Buy Fighters
                </a>
              </span>
            )}

            <span className={styles.Title}>My Backgrounds</span>
            {backgroundDTO.length > 0 ? (
              <CarouselWrap dots mode={1} backgroundDTO={backgroundDTO} />
            ) : (
              <span className={styles.BuyMessage}>
                You Have 0 Backgrounds
                {process.env.REACT_APP_EXTRAS_ACTIVE === 'true' && (
                  <LinkContainer
                    className={styles.BuyButton}
                    to={`${APP.MARKETPLACE}#backgrounds`}
                  >
                    <Nav.Link>Mint backgrounds</Nav.Link>
                  </LinkContainer>
                )}
              </span>
            )}
            <span className={styles.Title}>
              My Audio Taunts
              <CustomSelector
                selected={selected}
                itemList={nameList}
                onItemSelected={handleSelector}
              />
            </span>
            {audiosDTO.length > 0 ? (
              <CarouselWrap dots={false} mode={2} audioDTO={audiosDTO} />
            ) : (
              <span className={styles.BuyMessage}>
                You Have 0 Audios
                {process.env.REACT_APP_EXTRAS_ACTIVE === 'true' && (
                  <LinkContainer
                    className={styles.BuyButton}
                    to={`${APP.MARKETPLACE}#audiotaunts`}
                  >
                    <Nav.Link>Mint Audios</Nav.Link>
                  </LinkContainer>
                )}
              </span>
            )}
            <span className={styles.Title}>My Passes</span>
            {totalPasses > 0 ? (
              passIdOwned && (
                <CarouselWrap
                  dots
                  mode={5}
                  passesDTO={{
                    passIdOwned,
                  }}
                />
              )
            ) : (
              <span className={styles.BuyMessage}>
                You Have 0 Passes
                {process.env.REACT_APP_EXTRAS_ACTIVE === 'true' && (
                  <LinkContainer
                    className={styles.BuyButton}
                    to={`${APP.MARKETPLACE}#passes`}
                  >
                    <Nav.Link>Mint Passes</Nav.Link>
                  </LinkContainer>
                )}
              </span>
            )}
          </>
        ) : (
          <Loader forScreen />
        )
      ) : (
        <NotificationModal
          message="To see your assets first connect your wallet"
          isConnect
        />
      )}
    </section>
  );
};

export default MyNFTsHome;
