import React from 'react';
import { AnimatedOnScroll } from 'react-animated-css-onscroll';

import { CarouselWrap, GreenButton, ScoreBoard } from '../../../components';
import { MockCharacterCarouselCards } from '../../../lib/dtos';
import { useAppSelector } from '../../../redux/hooks/reduxHooks';
import { APP } from '../../../routes';
import styles from './End.module.css';

const End: React.FC = () => {
  const { scoreboard, topScoreboard } = useAppSelector(state => state.api);

  return (
    <section className={styles.Container}>
      <section className={styles.ItemsContainer}>
        <div className={styles.ItemsTitle}>
          <AnimatedOnScroll
            animationIn="fadeInUp"
            animateOnce
            animationInDelay={100}
          >
            <img
              className={styles.Line}
              src="assets/Landing/End/Line.svg"
              alt=""
            />
            Level Up Your Game
            <img
              className={styles.Line}
              src="assets/Landing/End/Line.svg"
              alt=""
            />
          </AnimatedOnScroll>
        </div>
        <div className={styles.ItemsSubContainer}>
          <AnimatedOnScroll animationIn="fadeInUp" animateOnce>
            <div className={styles.Item}>
              <img
                src="assets/Landing/End/ticket.png"
                className={styles.ItemImage}
                alt=""
              />
              <div className={styles.Description}>
                Purchase Fight Passes to engage in combat with friends, foes and auto-matched combatants, ascend the leaderboard and claim your a share of the $FIGHT rewards token.
              </div>
            </div>
          </AnimatedOnScroll>

          <AnimatedOnScroll animationIn="fadeInUp" animateOnce>
            <div className={styles.Item}>
              <img
                src="assets/Landing/End/bgs.png"
                className={styles.ItemImage}
                alt=""
              />
              <div className={styles.Description}>
                Enhance your 0xmen experience with a range of scenic backdrops from around the world.
              </div>
            </div>
          </AnimatedOnScroll>

          <AnimatedOnScroll animationIn="fadeInUp" animateOnce>
            <div className={styles.Item}>
              <img
                src="assets/Landing/End/taunts.png"
                className={styles.ItemImage}
                alt=""
              />
              <div className={styles.Description}>
                Let your opponent know how you feel with a selection of over 300 custom taunts, jabs and jibes in the characters own distinctive voice.
              </div>
            </div>
          </AnimatedOnScroll>
        </div>
      </section>
      <section className={styles.ScoreContainer}>
        <span className={styles.ScoreTitle}>
          <AnimatedOnScroll
            animationIn="fadeInLeft"
            animateOnce
            animationInDelay={100}
          >
            <img
              alt=""
              className={styles.Line}
              src="assets/Landing/End/Line.svg"
            />
            Top 10 Players
            <img
              alt=""
              className={styles.Line}
              src="assets/Landing/End/Line.svg"
            />
          </AnimatedOnScroll>
        </span>

        {scoreboard && (
          <div className={styles.ScoreBoard}>
            <ScoreBoard
              scoreBoardDTO={topScoreboard}
              displayAmount={10}
              isGlobal
              isTop
            />
          </div>
        )}

        <div className={styles.ScoreButton}>
          <AnimatedOnScroll animationIn="fadeInUp" animateOnce>
            <GreenButton route={APP.LEADERBOARD} text="SEE FULL RANKING" />
          </AnimatedOnScroll>
        </div>
      </section>
      <section className={styles.MeetContainer}>
        <div className={styles.MeetTitle}>
          <AnimatedOnScroll
            animationIn="fadeInUp"
            animateOnce
            animationInDelay={100}
          >
            <img
              alt=""
              className={styles.Line}
              src="assets/Landing/End/Line.svg"
            />
            Meet the 0xMen
            <img
              alt=""
              className={styles.Line}
              src="assets/Landing/End/Line.svg"
            />
          </AnimatedOnScroll>
        </div>

        <CarouselWrap dots mode={0} characterDTO={MockCharacterCarouselCards} />
      </section>
    </section>
  );
};

export default End;
