import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap/esm';

import { AnimatedOnScroll } from 'react-animated-css-onscroll';
import BoardPopUp from '../BoardPopUp/BoardPopUp';
import { HistoryDTO } from '../../lib/types';
import styles from './BoardItem.module.css';
import { useAppSelector } from '../../redux/hooks/reduxHooks';
import ImageLoader from '../ImageLoader/ImageLoader';

interface BoardItemProps {
  isGlobal?: boolean;
  index: number;
  tokenId: number;
  character: string;
  characterName: string;
  ranking: string;
  points: string;
  daypoints: string;
  itemAddress: string;
  trophy: string;
  fights: HistoryDTO[] | undefined;
  punchPower: string | undefined;
  kickPower: string | undefined;
  speed: string | undefined;
  stamina: string | undefined;
}

/**
 * Board row item component. Props:
 * @param {boolean?} isGlobal optional to render the YOURs in the characters that matches the connected address with their owner
 * @param {number} index to be able to render a special column row on top of the scoreboard if 0
 * @param {number} tokenId token id of the character to display in the pop up
 * @param {string} character the url to display an image of the character as
 * @param {string} characterName the name of the character
 * @param {string} ranking position of the character in the scoreboard, based in his points
 * @param {string} points current total points of the character
 * @param {string} daypoints points of the day
 * @param {string} itemAddress of the user owner of the character token id
 * @param {string} trophy internal url of the trophy thumbnail to display for top 3
 * @param {HistoryDTO[]?} fights array of fights to display in the history with needed props
 * @param {string?} punchPower stat of the character to display
 * @param {string?} kickPower stat of the character to display
 * @param {string?} speed stat of the character to display
 * @param {string?} stamina stat of the character to display
 */

const BoardItem: React.FC<BoardItemProps> = ({
  isGlobal,
  index,
  tokenId,
  character,
  characterName,
  ranking,
  points,
  daypoints,
  itemAddress,
  trophy,
  fights,
  punchPower,
  kickPower,
  speed,
  stamina,
}) => {
  BoardItem.defaultProps = {
    isGlobal: undefined,
  };
  const [show, setShow] = useState(false);
  const [modal, setModal] = useState(false);
  const [showPoints, setShowPoints] = useState('');
  const { address } = useAppSelector(state => state.connect);

  function selectStyle(selector: string) {
    switch (selector) {
    case '1 st': {
      return '2px solid #f3cd02';
    }
    case '2 nd': {
      return '2px solid #e8872d';
    }
    case '3 rd': {
      return '2px solid #717171';
    }
    default: {
      return '2px solid #ffffff';
    }
    }
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (modal) {
      handleClose();
      setModal(false);
    }
  }, [modal]);

  /* Effect to split the points slice without a fix and number convertion. Increment second pointsArr slice range to show more */
  useEffect(() => {
    if (points.includes('.')) {
      const pointsArr = points.split('.');
      setShowPoints(`${pointsArr[0]}.${pointsArr[1].slice(0, 1)}`);
    } else {
      setShowPoints(points);
    }
  }, [points]);

  const style = selectStyle(ranking);

  return (
    <AnimatedOnScroll animationIn="fadeInLeft" animateOnce>
      {index === 0 && (
        <div className={styles.BoardTitle}>
          <div className={styles.Columns}>
            <span>RANK</span>

            <span>CHARACTER</span>

            <span>SCORE</span>

            <span>ADDRESS</span>

            <span>DAY POINTS</span>
          </div>
        </div>
      )}
      <button
        className={styles.BoardItem}
        style={{ border: style }}
        type="button"
        aria-label="Open modal"
        onClick={handleShow}
      >
        <Modal
          contentClassName={styles.ModalContent}
          dialogClassName={styles.ModalDialog}
          show={show}
          animation
          onHide={() => setModal(true)}
          onClose={() => setModal(true)}
        >
          <BoardPopUp
            tokenId={tokenId}
            characterName={characterName}
            points={points}
            address={itemAddress}
            ranking={ranking}
            character={character}
            fights={fights}
            kickPower={kickPower}
            punchPower={punchPower}
            speed={speed}
            stamina={stamina}
          />
        </Modal>
        <div className={styles.TextContainer}>
          <span> {ranking}</span>
          <span>
            {character && (
              <ImageLoader
                alt=""
                className={styles.Thumbnail}
                src={character}
              />
            )}
          </span>
          <span> {`${characterName.slice(0, 8)}..`}</span>
          <div className={styles.Separator}>1</div>
          <span> {showPoints}</span>
          <div className={styles.Separator}>1</div>
          <span>
            {itemAddress &&
              `${itemAddress.slice(0, 4)}...${itemAddress.slice(40, 42)}`}
            <span className={styles.Ownership}>
              {address === itemAddress && isGlobal && 'YOURS'}
            </span>
          </span>
          <div className={styles.Separator} />
          <span> {daypoints}</span>

          <span>{trophy !== '' && <img src={trophy} alt="" />}</span>
        </div>
      </button>
    </AnimatedOnScroll>
  );
};

export default BoardItem;
