import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { ethers } from 'ethers';
import { Store } from 'react-notifications-component';

import { delayAlerts, networkToCheck } from '../../../config';

/**
 * Check if the chain is the correct one
 */
export const checkChain = async () => {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: networkToCheck.chainId }],
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (switchError: any) {
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: networkToCheck,
        });
      } catch (addError) {
        console.log(addError);
      }
    }
  }
};

/**
 * Thunk to init the connection with the choosen provider using the infura api key from the enviroment
 */
export const initProvider = createAsyncThunk('initProvider', async () => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const providerNetwork = await provider.getNetwork();
    if (providerNetwork.chainId !== Number(networkToCheck.chainId)) {
      console.error(
        `Incorrect chain, please check the enviroment variable for the chain id to match your desired chain or switch chain to ${networkToCheck.chainName}`,
      );
      checkChain();
      return { provider: null, currentChainId: providerNetwork.chainId };
    }
    return {
      provider,
      currentChainId: providerNetwork.chainId,
    };
  } catch (error) {
    console.log('Error initializing provider', error);
    throw error;
  }
});

/**
 * Thunk to connect the wallet of the user based on the choosen service.
 * Both metamask and walletconnect are displayed as buttons inside the modal.
 * @param {boolean} isWalletConnect if true the web will connect to walletConnect instead of metamask.
 */
export const connectWallet = createAsyncThunk(
  'connectWallet',
  async (action: { isWalletConnect?: boolean }) => {
    try {
      const setProvider = async () => {
        if (action.isWalletConnect) {
          const tempProvider = new WalletConnectProvider({
            bridge: 'https://bridge.walletconnect.org', // Required
            rpc: {
              1: process.env.REACT_APP_QUICKNODE_MAINNET || '',
              5: process.env.REACT_APP_QUICKNODE_GOERLI || '',
            },
          });
          await tempProvider.enable();

          return new ethers.providers.Web3Provider(tempProvider);
        }
        return new ethers.providers.Web3Provider(window.ethereum);
      };

      const provider = await setProvider();
      const providerNetwork = await provider.getNetwork();

      if (!action.isWalletConnect) {
        await provider.send('eth_requestAccounts', []);
      }

      if (providerNetwork.chainId === Number(networkToCheck.chainId)) {
        const signer = provider.getSigner();
        const account = await signer.getAddress();
        const balanceETH = Number(
          ethers.utils.formatEther(await signer.getBalance()),
        );
        return {
          provider,
          signer,
          account,
          balanceETH,
          isWalletConnect: action.isWalletConnect || false,
          currentChainId: providerNetwork.chainId,
        };
      }

      checkChain();
      console.error(
        `Incorrect chain, please check the enviroment variable for the chain id to match your desired chain or switch chain to ${networkToCheck.chainName}`,
      );
      return {
        provider: null,
        signer: null,
        account: null,
        balanceETH: 0,
        isWalletConnect: false,
        currentChainId: null,
      };
    } catch (error) {
      console.log('Error initializing json rpc provider', error);
      throw error;
    }
  },
);

/**
 * Checks if the user can be auto connected to metamask if available, if the user hasn´t been using walletconnect
 */
export const checkConnection = createAsyncThunk(
  'checkConnection',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action, thunkAPI: any) => {
    try {
      const accounts: string[] = await window.ethereum.request({
        method: 'eth_accounts',
      });
      const { isWalletConnect } = thunkAPI.getState().connect;
      if (accounts.length > 0 && !isWalletConnect) {
        await thunkAPI.dispatch(connectWallet({}));
      }
    } catch (error) {
      console.log('Error initializing metamask account', error);
      throw error;
    }
  },
);

/**
 * Checks if the user can be auto connected to metamask if available, if the user hasn´t been using walletconnect
 */
export const fetchETH = createAsyncThunk(
  'fetchETH',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action, thunkAPI: any) => {
    try {
      const { signer } = thunkAPI.getState().connect;
      const balanceETH = Number(
        ethers.utils.formatEther(await signer.getBalance()),
      );
      return { balanceETH };
    } catch (error) {
      console.log('Error fetching ethereum balance', error);
      Promise.resolve(setTimeout(() => {}, 1000));
      thunkAPI.dispatch(fetchETH());
      throw error;
    }
  },
);

export type ConnectState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  provider: any;
  connecting: boolean;
  // alert booleans, to avoid repeat alert on load re-renders
  connectedAlert: boolean;
  accountChangeAlert: boolean;
  address: string | null;
  balanceETH: number;
  signer: ethers.providers.JsonRpcSigner | null;
  cachedConnection: boolean;
  userFetched: boolean;
  isWalletConnect: boolean;
  currentChainId: number | null;
};

const initialConnectState: ConnectState = {
  provider: null,
  connecting: false,
  connectedAlert: false,
  accountChangeAlert: false,
  address: null,
  balanceETH: 0,
  signer: null,
  cachedConnection: false,
  userFetched: false,
  isWalletConnect: false,
  currentChainId: null,
};

const connectSlice = createSlice({
  name: 'connectReducer',
  initialState: initialConnectState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    disconnectWallet: (state: any) => {
      if (state.address) {
        if (state.isWalletConnect) {
          state.provider.disconnect();
        }

        state.provider = null;
        state.connecting = false;
        state.address = null;
        state.balanceETH = 0;
        state.signer = null;
        state.connectedAlert = false;
        state.accountChangeAlert = false;
      }

      Store.addNotification({
        title: 'Disconnected',
        message: 'Wallet disconnected',
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: delayAlerts,
          onScreen: true,
        },
      });
    },

    startConnection: (state: ConnectState) => {
      state.connecting = true;
    },
    stopConnection: (state: ConnectState) => {
      state.connecting = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setUserFetched: (state: any, action: any) => {
      state.userFetched = action.payload.set;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setCurrentChainId: (state: any, action: any) => {
      state.currentChainId = action.payload.currentChainId;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(connectWallet.rejected, state => {
        state.connecting = false;
      })
      .addCase(initProvider.fulfilled, (state, action) => {
        state.provider = action.payload.provider;
        state.currentChainId = action.payload.currentChainId;
      })
      .addCase(fetchETH.fulfilled, (state, action) => {
        state.balanceETH = action.payload.balanceETH;
      })
      .addCase(connectWallet.fulfilled, (state, action) => {
        state.provider = action.payload.provider;
        state.signer = action.payload.signer;
        state.address = action.payload.account;
        state.balanceETH = action.payload.balanceETH;
        state.connecting = false;
        state.isWalletConnect = action.payload.isWalletConnect;
        state.currentChainId = action.payload.currentChainId;

        if (state.connectedAlert === false) {
          Store.addNotification({
            title: 'Connected',
            message: 'Wallet connected',
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: delayAlerts,
              onScreen: true,
            },
          });

          setTimeout(
            () =>
              Store.addNotification({
                title: 'Account',
                message: 'Please wait 10s while we fetch all the data',
                type: 'info',
                insert: 'top',
                container: 'top-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                  duration: delayAlerts,
                  onScreen: true,
                },
              }),
            delayAlerts + 1000,
          );

          state.connectedAlert = true;
        }
      });
  },
});

export const connectReducer = connectSlice.reducer;
export const {
  disconnectWallet,
  startConnection,
  stopConnection,
  setUserFetched,
  setCurrentChainId,
} = connectSlice.actions;
