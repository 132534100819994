import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { arrayify } from 'ethers/lib/utils';
import { Store } from 'react-notifications-component';

import { delayAlerts } from '../../../config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleMatchPending = (error: any) => {
  if (
    error.response.data.message ===
    '[InfrastructureError] Player already has a room created [4]'
  ) {
    Store.addNotification({
      title: 'Matchmaking',
      message:
        'You have a match already created. Wait 10 minutes and try again',
      type: 'warning',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: delayAlerts,
        onScreen: true,
      },
    });
  } else {
    console.log('Error on matchmaking. Check room API', error);
  }
};

/**
 * Thunk to check if the user has created a room that can be deleted.
 * This needs to be called when the user gets back from the fight screen and when the user logs in first time
 * Since only the host user saves the roomId in cache, only the creator will call the delete, only if the match didnt started (status 2)
 * Otherwise the backend will delete the match after some minutes for security/
 */
export const resetCheck = createAsyncThunk(
  'resetCheck',
  async (
    action,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    thunkAPI: any,
  ) => {
    try {
      const leftRoom = window.localStorage.getItem('roomId');
      const { address } = thunkAPI.getState().connect;
      if (leftRoom && leftRoom.length > 20 && address) {
        const { axiosInstance } = thunkAPI.getState().api;

        const params = {
          roomId: leftRoom,
          playerAddress: address,
        };

        const roomData = await axiosInstance.get(
          `infrastructure/rooms/${leftRoom}`,
          {
            params,
          },
        );
        if (roomData.data.status < 2) {
          axiosInstance.delete(`infrastructure/rooms/${leftRoom}/${address}`, {
            params,
          });
        }
        window.localStorage.removeItem('roomId');
      }
    } catch (error) {
      console.log('Error deleting a room from api');
      throw error;
    }
  },
);

/**
 * Thunk to register interest to search for a random room
 * All params needs to be wrapped inside an object as properties
 * @param {{ passId: number; tokenId: number }} passDTO an object containing passId:number and tokenId:number
 * @param {number} characterTokenId token id of the character that is searching
 * @param {string} playerAddress player address that is searching
 */
export const randomSearch = createAsyncThunk(
  'randomSearch',
  async (
    action: {
      characterTokenId: number;
      playerAddress: string;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    thunkAPI: any,
  ) => {
    try {
      const { address, provider } = thunkAPI.getState().connect;
      const { selectedPassId, selectedTokenId } = thunkAPI.getState().room;
      const { axiosInstance } = thunkAPI.getState().api;

      const params = {
        passId: selectedPassId,
        tokenId: selectedTokenId,
        spectators: false,
      };

      const hash = await axiosInstance.get('passes/message', {
        params,
      });

      const keck = arrayify(hash.data);

      const sign = async () => {
        try {
          console.log('signing message');
          const signer = await provider.getSigner();
          const signed = await signer.signMessage(keck);
          return signed;
        } catch (error) {
          console.log('Error signing a meesage');
          throw error;
        }
      };
      const signature = await sign();

      const signedPassDTO = {
        passId: selectedPassId,
        tokenId: selectedTokenId,
        signature,
      };

      const roomId = await axiosInstance.post('infrastructure/rooms/random', {
        passDTO: signedPassDTO,
        characterTokenId: action.characterTokenId,
        playerAddress: address,
      });

      window.localStorage.setItem('roomId', roomId.data);

      return {
        roomId: roomId.data,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      handleMatchPending(error);
      throw error;
    }
  },
);

/**
 * Thunk to create a room and invite a player. Saves the room id and displays it to the user
 * All params needs to be wrapped inside an object as properties
 * @param {boolean} stream boolean to choose if the game will be streamed
 * @param {number} characterTokenId token id number of the character that is searching
 * @param {string} playerAddress address of the player that is searching
 */
export const createRoom = createAsyncThunk(
  'createRoom',
  async (
    action: {
      stream: boolean;
      characterTokenId: number;
      playerAddress: string;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    thunkAPI: any,
  ) => {
    try {
      const { address, provider } = thunkAPI.getState().connect;
      const { selectedPassId, selectedTokenId } = thunkAPI.getState().room;
      const { axiosInstance } = thunkAPI.getState().api;

      const params = {
        passId: selectedPassId,
        tokenId: selectedTokenId,
        spectators: action.stream,
      };

      const hash = await axiosInstance.get('passes/message', {
        params,
      });

      const keck = arrayify(hash.data);

      const sign = async () => {
        try {
          console.log('signing message');
          const signer = await provider.getSigner();
          const signed = await signer.signMessage(keck);
          return signed;
        } catch (error) {
          console.log('Error signing a meesage');
          throw error;
        }
      };
      const signature = await sign();

      const signedPassDTO = {
        passId: selectedPassId,
        tokenId: selectedTokenId,
        signature,
      };

      const roomId = await axiosInstance.post('infrastructure/rooms/friendly', {
        spectators: action.stream,
        passDTO: signedPassDTO,
        characterTokenId: action.characterTokenId,
        playerAddress: address,
      });

      window.localStorage.setItem('roomId', roomId.data);

      return {
        roomId: roomId.data,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      handleMatchPending(error);
      throw error;
    }
  },
);

/**
 * Thunk to fetch the data of a room. It returns an object with the data to join the room, like the FQDN and port
 * All params needs to be wrapped inside an object as properties
 * @param roomId room id of the room to fetch
 */
export const fetchRoom = createAsyncThunk(
  'fetchRoom',
  async (
    action,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    thunkAPI: any,
  ) => {
    try {
      const { axiosInstance } = thunkAPI.getState().api;
      const { roomId } = thunkAPI.getState().room;

      const params = {
        roomId,
      };

      const roomData = await axiosInstance.get(
        `infrastructure/rooms/${roomId}`,
        {
          params,
        },
      );

      return {
        roomConnector: roomData.data,
      };
    } catch (error) {
      console.log('Error fetching a room from api', error);
      Promise.resolve(setTimeout(() => {}, 1000));
      thunkAPI.dispatch(fetchRoom());
      throw error;
    }
  },
);

/**
 * Thunk to register the join of a room
 * All params needs to be wrapped inside an object as properties
 * @param {boolean} stream boolean to choose if the game will be streamed
 * @param {{ passId: number; tokenId: number }} passDTO an object containing passId:number and tokenId:number
 * @param {string} roomId id of the room returned to the first user that created the room
 * @param {number} characterTokenId token id number of the character that is searching
 * @param {string} playerAddress address of the player that is searching
 */
export const joinRoom = createAsyncThunk(
  'joinRoom',
  async (
    action: {
      stream: boolean;
      roomId: string;
      characterTokenId: number;
      playerAddress: string;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    thunkAPI: any,
  ) => {
    try {
      const { address, provider } = thunkAPI.getState().connect;
      const { selectedPassId, selectedTokenId } = thunkAPI.getState().room;
      const { axiosInstance } = thunkAPI.getState().api;

      const params = {
        passId: selectedPassId,
        tokenId: selectedTokenId,
        spectators: false,
      };

      const hash = await axiosInstance.get('passes/message', {
        params,
      });

      const keck = arrayify(hash.data);

      const sign = async () => {
        try {
          console.log('signing message');
          const signer = await provider.getSigner();
          const signed = await signer.signMessage(keck);
          return signed;
        } catch (error) {
          console.log('Error signing a meesage');
          throw error;
        }
      };
      const signature = await sign();

      const signedPassDTO = {
        passId: selectedPassId,
        tokenId: selectedTokenId,
        signature,
      };

      const roomData = await axiosInstance.patch(
        'infrastructure/rooms/friendly',
        {
          pendingRoomID: action.roomId,
          passDTO: signedPassDTO,
          characterTokenId: action.characterTokenId,
          playerAddress: address,
        },
      );

      return {
        roomId: action.roomId,
        roomConnector: roomData.data,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      handleMatchPending(error);
      throw error;
    }
  },
);

interface ApiInitialState {
  roomId: string;
  selectedPassId: number | null;
  selectedTokenId: number | null;
  roomConnector: {
    status: number;
    spectators: boolean;
    FQDN: string;
    port: number;
  };
  playing: boolean;
  hidePassSelector: boolean;
}

const initialState: ApiInitialState = {
  roomId: '',
  selectedPassId: null,
  selectedTokenId: null,
  roomConnector: {
    status: -1,
    spectators: false,
    FQDN: '',
    port: 8001,
  },
  playing: false,
  hidePassSelector: false,
};

const roomSlice = createSlice({
  name: 'roomReducer',
  initialState,
  reducers: {
    /**
     * It changes the room Id if the user wants to specify join one
     * @param action an object containing a parameter roomId: string
     */
    setPlaying: (state: ApiInitialState, action) => {
      state.playing = action.payload.playing;
    },
    /**
     * It changes the room Id if the user wants to specify join one
     * @param action an object containing a parameter roomId: string
     */
    changeRoomId: (state: ApiInitialState, action) => {
      state.roomId = action.payload.roomId;
    },
    /**
     * It reset the room data to the initial so the dApp will stop trying to fetch
     * the room and go back to first step before
     * register the user intention to play a match
     */
    resetRoom: (state: ApiInitialState) => {
      state.roomId = '';
      state.roomConnector = {
        status: -1,
        spectators: false,
        FQDN: '',
        port: 8001,
      };
    },
    /**
     * It changes the pass to consume needed to join, create, search and watch a room
     * @param action an object containing a parameter passId: number and tokenId: number
     */
    changePassToConsume: (state: ApiInitialState, action) => {
      state.selectedPassId = action.payload.selectedPassId;
      state.selectedTokenId = action.payload.selectedTokenId;
      state.hidePassSelector = !state.hidePassSelector;
    },
  },
  extraReducers: builder => {
    builder.addCase(createRoom.fulfilled, (state, action) => {
      state.roomId = action.payload.roomId;

      Store.addNotification({
        title: 'Hosted',
        message: 'Room created',
        type: 'info',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: delayAlerts,
          onScreen: true,
        },
      });
    });
    builder.addCase(joinRoom.fulfilled, (state, action) => {
      state.roomId = action.payload.roomId;

      Store.addNotification({
        title: 'Joining',
        message: 'Requested room join',
        type: 'info',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: delayAlerts,
          onScreen: true,
        },
      });
    });
    builder.addCase(randomSearch.fulfilled, (state, action) => {
      state.roomId = action.payload.roomId;

      Store.addNotification({
        title: 'Matchmaking',
        message: 'Searching for a player',
        type: 'info',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: delayAlerts,
          onScreen: true,
        },
      });
    });
    builder.addCase(fetchRoom.fulfilled, (state, action) => {
      if (state.roomId.length > 0) {
        // Avoid keey reloading if the roomId is reseted. Dont get roomConnector if a random fetch keeps alive after cancel.
        state.roomConnector = action.payload.roomConnector;
      }
    });
  },
});

export const roomReducer = roomSlice.reducer;
export const { changeRoomId, changePassToConsume, setPlaying, resetRoom } =
  roomSlice.actions;
