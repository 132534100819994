export const networkToCheck = {
  chainId: process.env.REACT_APP_CHAIN_ID || '',
  chainName: process.env.REACT_APP_CHAIN_NAME || '',
  nativeCurrency: {
    name: process.env.REACT_APP_CHAIN || '',
    symbol: process.env.REACT_APP_CHAIN_SYMBOL || '',
    decimals: process.env.REACT_APP_CHAIN_DECIMALS || '',
  },
  blockExplorerUrls: [`${process.env.REACT_APP_EXPLORER || ''}`],
};

/* Delay on each screen change in the website, this may scale depending on the backend or blockchain traffic overflow and timings */
export const delayPageLoad = Number(process.env.REACT_APP_LOADER_DELAY) || 2500;
/* Delay for each alert showed in the dApp */
export const delayAlerts = Number(process.env.REACT_APP_ALERTS_DELAY) || 2500;
/* Timer end date. When the timer reachs the date it displays 0:00:00 forever */
export const timerEnds = new Date(`${process.env.REACT_APP_TIMER_END_DATE}`);

/* Terms, privacy and social media links, will redirect to home if undefined */
export const telegramLink = process.env.REACT_APP_TELEGRAM || '/';
export const discordLink = process.env.REACT_APP_DISCORD || '/';
export const twitterLink = process.env.REACT_APP_TWITTER || '/';
export const termsUseLink = process.env.REACT_APP_TERMS || '/';
export const privacyPolicyLink = process.env.REACT_APP_PRIVACY_POLICY || '/';

export const openseaCollection = {
  name: process.env.REACT_APP_COLLECTION_NAME || '',
  assetBaseUri: process.env.REACT_APP_COLLECTION_ASSET || '',
  collectionBaseUri: process.env.REACT_APP_COLLECTION_URI || '',
};

export const contractAddresses = {
  Fight: process.env.REACT_APP_FIGHT || '',
  FightMinter: process.env.REACT_APP_FIGHTMINTER || '',
  Characters: process.env.REACT_APP_CHARACTERS || '',
  CharacterMinter: process.env.REACT_APP_CHARACTERMINTER || '',
  XConsoles: process.env.REACT_APP_XCONSOLES || '',
  Background: process.env.REACT_APP_BACKGROUND || '',
  AudioTaunt: process.env.REACT_APP_AUDIOTAUNT || '',
  ExtrasMinter: process.env.REACT_APP_EXTRASMINTER || '',
  Passes: [
    {
      address: process.env.REACT_APP_PASSES_0 || '',
    },
    {
      address: process.env.REACT_APP_PASSES_1 || '',
    },
    {
      address: process.env.REACT_APP_PASSES_2 || '',
    },
    {
      address: process.env.REACT_APP_PASSES_3 || '',
    },
  ],
  PassMinter: process.env.REACT_APP_PASSMINTER || '',
};
