import React from 'react';

import styles from './Timer.module.css';

interface TimerProp {
  EndDate: Date;
}
/**
 * Component to render the timer of the landing page. Props
 * @param {Date} EndDate end date of the counter. When it finishes it will display 0:00:00
 */
const Timer: React.FC<TimerProp> = ({ EndDate }) => {
  const [date, setDateTo] = React.useState<Date>(new Date());

  React.useEffect(() => {
    const interval: NodeJS.Timer = setInterval(() => {
      const update: Date = new Date();

      if (update.getSeconds() !== date.getSeconds()) {
        setDateTo(update);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [date]);

  const getTime = (): string => {
    const timePending = (EndDate.getTime() - date.getTime()) / 1000;

    const hours: string = Math.floor(
      (timePending % (3600 * 24)) / 3600,
    ).toString();
    const minutes: string = Math.floor((timePending % 3600) / 60)
      .toFixed(0)
      .toString();
    const seconds: string = Math.floor(timePending % 60).toString();

    const timeString = `${hours} : ${
      Number(minutes) > 9 ? minutes : `${0 + minutes}`
    } : ${Number(seconds) > 9 ? seconds : `${0 + seconds}`}`;

    return timePending > 0 ? timeString : '0:00:00'; // Change the 0:00:00 to another finished message if needed
  };

  return <div className={styles.TimerText}>{getTime()}</div>;
};

export default Timer;
