import React, { useState } from 'react';
import Nav from 'react-bootstrap/esm/Nav';
import { LinkContainer } from 'react-router-bootstrap';

import { OwnedAudios } from '../../lib/types';
import { handlePurchase } from '../../lib/utils';
import { useAppDispatch } from '../../redux/hooks/reduxHooks';
import { APP } from '../../routes';
import styles from './AudioCard.module.css';

/**
 * Audio card component. Has 2 modes depending on viewMode prop. If !viewmode the card is for buy. Props:
 * @param {string} title to show on top of the card. Needs to be the name of the character
 * @param {number?} id token id of the pass
 * @param {number?} amount amount of owned ids for the carousel on myNfts screen
 * @param {string?} price optiona, to buy the pass. The buy UI won´t be rendered if no price is passed
 * @param {string} characterName name to display below the play button.
 * @param {string} audioTrack the url to play the track
 */
const AudioCard: React.FC<OwnedAudios> = ({
  title,
  id,
  amount,
  price,
  characterName,
  audioTrack,
}) => {
  const [play, setPlay] = useState(false);
  const dispatch = useAppDispatch();

  const audio = new Audio(audioTrack);

  const handlePlay = () => {
    if (play) {
      audio.pause();
    } else {
      audio.play();
      audio.addEventListener('ended', () => {
        setPlay(false);
      });
    }
    setPlay(!play);
  };

  return (
    <div
      className={styles.AudioCardContainer}
      style={{ background: play ? '#002121' : '#0e0321' }}
    >
      <span className={styles.Title}>{title}</span>
      {price && <span className={styles.Subtitle}>$Fight: {price}</span>}
      <div className={styles.AudioCardSubcontainer}>
        <button
          type="button"
          className={styles.AudioButton}
          onClick={handlePlay}
        >
          {amount && (
            <div className={styles.CharacterName}>You have {amount}</div>
          )}
          <img
            src={
              play
                ? 'assets/Components/AudioCard/Sound_Off.svg'
                : 'assets/Components/AudioCard/Sound_On.svg'
            }
            alt=""
          />
        </button>
        <div className={styles.CharacterName}>{characterName}</div>

        {price && (
          <LinkContainer to={APP.BUY} className={styles.BuyButton}>
            <Nav.Link
              onClick={() =>
                handlePurchase(
                  1,
                  2,
                  dispatch,
                  undefined,
                  {
                    title,
                    price,
                    characterName,
                    audioTrack,
                    id,
                  },
                  undefined,
                  id,
                )
              }
            >
              Buy
            </Nav.Link>
          </LinkContainer>
        )}
      </div>
    </div>
  );
};

export default AudioCard;
