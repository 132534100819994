import React from 'react';

import styles from './Loader.module.css';

/**
 * Loader component, it renders a loader using all the available space in the container.
 * This Loader scales based on viewport width to match a general size acrross all the website
 * @param {boolean | undefined} forImages boolean to choose between style to preload images or for anything else
 * @param {boolean | undefined} forScreen boolean to choose between style for bigger screen loaders or for individual smaller ones if undefined

*/
interface LoaderProps {
  forImages?: boolean;
  forScreen?: boolean;
}
const Loader: React.FC<LoaderProps> = ({ forImages, forScreen }) => {
  Loader.defaultProps = {
    forImages: undefined,
    forScreen: undefined,
  };
  return forImages ? (
    <div className={styles.LoaderContainer}>
      <img
        className={styles.ImageLoader}
        src="assets/Loader/Loader.gif"
        alt=""
      />
    </div>
  ) : (
    <img
      className={forScreen ? styles.ScreenLoader : styles.Loader}
      src="assets/Loader/Loader.gif"
      alt=""
    />
  );
};

export default Loader;
