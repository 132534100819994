import React from 'react';
import {
  contractAddresses,
  networkToCheck,
  openseaCollection,
} from '../../config';
import { CharacterDTO } from '../../lib/types';
import SkillBox from '../SkillBox/SkillBox';

import styles from './CharacterCard.module.css';
import ImageLoader from '../ImageLoader/ImageLoader';

/**
 * Card component to display the character. Props:
 * @param {CharacterDTO & {color?: string;}} characterDTO dto to display the characters. May have a color parameter to set in the background.
 */
const CharacterCard: React.FC<
  CharacterDTO & {
    color?: string;
  }
> = ({ name, description, character, tokenId, skills, color }) => {
  CharacterCard.defaultProps = {
    color: '#aeae13',
  };

  return (
    <div className={styles.CardContainer}>
      <div className={styles.Card} style={{ backgroundColor: color }}>
        <div className={styles.Showcase}>
          <div className={styles.Data}>
            <span className={styles.Name}>{name}</span>
            {tokenId && <span className={styles.Id}>Token id: {tokenId}</span>}
            <a
              className={styles.ViewButton}
              href={
                tokenId
                  ? `${openseaCollection.assetBaseUri}/${networkToCheck.chainName}/${contractAddresses.Characters}/${tokenId}`
                  : openseaCollection.collectionBaseUri + openseaCollection.name
              }
              target="_blank"
              rel="noreferrer"
            >
              View NFT
            </a>
          </div>
          <ImageLoader className={styles.Thumbnail} src={character} alt="" />
        </div>
        <p className={styles.Description}>{description}</p>

        {skills && (
          <div className={styles.Skills}>
            <SkillBox
              responsive
              skills={{
                punchPower: skills[5].value ? skills[5].value.toString() : '0',
                kickPower: skills[6].value ? skills[6].value.toString() : '0',
                speed: skills[7].value ? skills[7].value.toString() : '0',
                stamina: skills[8].value ? skills[8].value.toString() : '0',
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CharacterCard;
