import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosInstance } from 'axios';
import { Store } from 'react-notifications-component';
import { ethers } from 'ethers';

import { HistoryDTO, ScoreBoardDTO } from '../../../lib/types';
import { fixArweaveURI } from '../nftReducer/nftReducer';
import { delayAlerts } from '../../../config';
import { fetchBalance } from '../tokenReducer/tokenReducer';

/**
 * Thunk to fetch the rewards of the connected user
 */
export const fetchRewards = createAsyncThunk(
  'fetchRewards',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action, thunkAPI: any) => {
    try {
      const { address } = thunkAPI.getState().connect;
      const { axiosInstance } = thunkAPI.getState().api;
      const reward = await axiosInstance.get(`/rewards/${address}`);

      if (reward && reward.data && reward.data.tokenAmount) {
        const etherReward = Number(
          ethers.utils.formatEther(reward.data.tokenAmount),
        );
        return { totalReward: etherReward };
      }
      return { totalReward: 0 };
    } catch (error) {
      console.log('Error fetching rewards from api', error);
      Promise.resolve(setTimeout(() => {}, 1000));
      thunkAPI.dispatch(fetchRewards());
      throw error;
    }
  },
);

/**
 * Thunk to trigger the claim rewards tx for the connected user
 */
export const claimRewards = createAsyncThunk(
  'claimRewards',
  // eslint-disable-next-line
  async (action, thunkAPI: any) => {
    try {
      const { address, provider } = thunkAPI.getState().connect;
      const { tokenMinterInstance } = thunkAPI.getState().token;
      const { axiosInstance } = thunkAPI.getState().api;

      const rewardSignature = await axiosInstance.get(
        `/rewards/${address}/signature`,
      );

      const { signature, nonce, to, amount, expiration } = rewardSignature.data;

      const tx = await tokenMinterInstance.claimReward(
        to,
        amount,
        expiration,
        nonce,
        signature,
      );

      await provider.waitForTransaction(tx.hash);

      thunkAPI.dispatch(fetchRewards());
      thunkAPI.dispatch(fetchBalance());
    } catch (error) {
      console.log('Error claiming rewards from api', error);
      throw error;
    }
  },
);

/**
 * Thunk to fetch the global scoreboard only
 */
export const fetchGlobalScoreboard = createAsyncThunk(
  'fetchGlobalScoreboard',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action, thunkAPI: any) => {
    try {
      const { axiosInstance } = thunkAPI.getState().api;
      const { globalStart, globalLength } = thunkAPI.getState().api;

      const scoreboard = await axiosInstance.get('/scores/scoreboard', {
        params: {
          start: globalStart,
          length: globalLength,
        },
      });

      const fixedScoreboard = scoreboard
        ? scoreboard.data.map((dto: ScoreBoardDTO) => {
          dto.thumbnail = fixArweaveURI(dto.thumbnail);
          dto.fightHistory = dto.fightHistory
            ? dto.fightHistory.map((hDto: HistoryDTO) => {
              hDto.thumbnail = fixArweaveURI(hDto.thumbnail);
              return hDto;
            })
            : undefined;
          return dto;
        })
        : null;

      return {
        scoreboard: fixedScoreboard,
      };
    } catch (error) {
      console.log('Error fetching scoreboard from api', error);
      Promise.resolve(setTimeout(() => {}, 1000));
      thunkAPI.dispatch(fetchGlobalScoreboard());
      throw error;
    }
  },
);

/**
 * Thunk to fetch only the top 10 scoreboard
 */
export const fetchTopScoreboard = createAsyncThunk(
  'fetchTopScoreboard',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action, thunkAPI: any) => {
    try {
      const { axiosInstance } = thunkAPI.getState().api;

      const topScoreboard = await axiosInstance.get('/scores/scoreboard', {
        params: {
          start: 0,
          length: 10,
        },
      });

      const fixedScoreboard =
        topScoreboard && topScoreboard.data
          ? topScoreboard.data.map((dto: ScoreBoardDTO) => {
            dto.thumbnail = fixArweaveURI(dto.thumbnail);
            dto.fightHistory = dto.fightHistory
              ? dto.fightHistory.map((hDto: HistoryDTO) => {
                hDto.thumbnail = fixArweaveURI(hDto.thumbnail);
                return hDto;
              })
              : undefined;
            return dto;
          })
          : null;

      return {
        topScoreboard: fixedScoreboard,
      };
    } catch (error) {
      console.log('Error fetching scoreboard from api', error);
      Promise.resolve(setTimeout(() => {}, 1000));
      thunkAPI.dispatch(fetchTopScoreboard());
      throw error;
    }
  },
);

/**
 * Thunk to fetch the personal scoreboard of the connected user
 */
export const fetchPersonalScoreboard = createAsyncThunk(
  'fetchPersonalScoreboard',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action, thunkAPI: any) => {
    try {
      const { address } = thunkAPI.getState().connect;

      if (!address) return { personalScoreboard: null };

      const { axiosInstance } = thunkAPI.getState().api;

      const { personalStart, personalLength } = thunkAPI.getState().api;

      const personalScoreboard = await axiosInstance.get(
        '/scores/scoreboard/',
        {
          params: {
            start: personalStart,
            length: personalLength,
            address,
          },
        },
      );

      const fixedPersonalScoreboard =
        personalScoreboard && personalScoreboard.data.length > 0
          ? personalScoreboard.data.map((dto: ScoreBoardDTO) => {
            dto.thumbnail = fixArweaveURI(dto.thumbnail);
            dto.fightHistory = dto.fightHistory
              ? dto.fightHistory.map((hDto: HistoryDTO) => {
                hDto.thumbnail = fixArweaveURI(hDto.thumbnail);
                return hDto;
              })
              : undefined;
            return dto;
          })
          : null;

      return {
        personalScoreboard: fixedPersonalScoreboard,
      };
    } catch (error) {
      console.log('Error fetching scoreboard from api', error);
      Promise.resolve(setTimeout(() => {}, 1000));
      thunkAPI.dispatch(fetchPersonalScoreboard());
      throw error;
    }
  },
);

/**
 * Thunk to fetch the rewards of the connected user
 */
export const initApi = createAsyncThunk(
  'initApi',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async () => {
    try {
      const instance = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        timeout: 10000,
        headers: {
          crossDomain: true,
          accept: 'application/json',
        },
      });

      return { instance };
    } catch (error) {
      console.log(
        'Error creating axios instance, please check if the enviroment configuration its fine',
        error,
      );
      throw error;
    }
  },
);

interface ApiInitialState {
  axiosInstance: AxiosInstance | null;
  rewards: number;
  claimed: boolean;
  globalStart: number;
  globalLength: number;
  personalStart: number;
  personalLength: number;
  scoreboard: ScoreBoardDTO[] | null;
  personalScoreboard: ScoreBoardDTO[] | null;
  topScoreboard: ScoreBoardDTO[] | null;
}

const initialState: ApiInitialState = {
  axiosInstance: null,
  rewards: 0,
  claimed: false,
  globalStart: 0,
  globalLength: 10,
  personalStart: 0,
  personalLength: 10,
  scoreboard: null,
  personalScoreboard: null,
  topScoreboard: null,
};

const apiSlice = createSlice({
  name: 'apiReducer',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    changeScoreFetch: (state: ApiInitialState, action: any) => {
      if (action.payload.isGlobal) {
        state.globalStart = action.payload.start;
        state.globalLength = action.payload.length;
      } else {
        state.personalStart = action.payload.start;
        state.personalLength = action.payload.length;
      }
    },
    resetClaim: (state: ApiInitialState) => {
      state.claimed = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(initApi.fulfilled, (state, action) => {
        state.axiosInstance = action.payload.instance;
      })
      .addCase(fetchRewards.fulfilled, (state, action) => {
        state.rewards = action.payload.totalReward;
      })
      .addCase(fetchGlobalScoreboard.fulfilled, (state, action) => {
        state.scoreboard = action.payload.scoreboard;
      })
      .addCase(fetchPersonalScoreboard.fulfilled, (state, action) => {
        state.personalScoreboard = action.payload.personalScoreboard;
      })
      .addCase(fetchPersonalScoreboard.rejected, state => {
        state.personalScoreboard = null;
      })
      .addCase(fetchTopScoreboard.fulfilled, (state, action) => {
        state.topScoreboard = action.payload.topScoreboard;
      })
      .addCase(claimRewards.rejected, state => {
        Store.addNotification({
          title: 'Claim',
          message: 'Error claiming your reward.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: delayAlerts,
            onScreen: true,
          },
        });

        state.claimed = true;
      })
      .addCase(claimRewards.fulfilled, state => {
        const now = new Date();
        now.setTime(now.getTime() + 3600 * 1000);
        document.cookie = `rewardClaim=true; expires=${now.toUTCString()}; path=/;SameSite = None`;

        Store.addNotification({
          title: 'Claim',
          message: 'You have claimed your fight',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: delayAlerts,
            onScreen: true,
          },
        });

        state.claimed = true;
      });
  },
});

export const apiReducer = apiSlice.reducer;
export const { changeScoreFetch, resetClaim } = apiSlice.actions;
