import React, { useEffect, useState } from 'react';
import { Store } from 'react-notifications-component';
import Loader from '../../components/Loader/Loader';

import ScoreBoard from '../../components/ScoreBoard/ScoreBoard';
import { useAppSelector } from '../../redux/hooks/reduxHooks';
import styles from './LeaderboardHome.module.css';
import { delayAlerts } from '../../config';

const LeaderboardHome: React.FC = () => {
  const [toggleBoard, setToggleBoard] = useState(false); // Toggle to choose between personal or global scoreboard. Global if false
  const { scoreboard, personalScoreboard } = useAppSelector(state => state.api);
  const { charactersIdOwned } = useAppSelector(state => state.nft);
  const { address } = useAppSelector(state => state.connect);
  const [personalAvailable, setPersonal] = useState(false); // Personal scoreboard available to display boolean
  const showAllFighters = () => setToggleBoard(false);
  const showMyNFTs = () => {
    if (personalAvailable) {
      setToggleBoard(true);
    } else {
      Store.addNotification({
        title: 'Scoreboard',
        message: "You can't see the personal score unless you have characters",
        type: 'info',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: delayAlerts,
          onScreen: true,
        },
      });
    }
  };

  useEffect(() => {
    if (
      address &&
      charactersIdOwned &&
      charactersIdOwned.length > 0 &&
      personalScoreboard !== null &&
      personalScoreboard.length > 0
    ) {
      setPersonal(true);
    } else {
      setPersonal(false);
      setToggleBoard(false);
    }
  }, [address, charactersIdOwned, personalScoreboard]);

  return (
    <section className={styles.HomeContainer}>
      <section className={styles.BannerContainer}>
        <div className={styles.BannerTop}>
          <img src="assets/Score/Home/BannerTop.svg" alt="" />
        </div>

        <img src="assets/Score/Home/Banner.png" alt="" />
      </section>
      <div className={styles.TabContainer}>
        <button
          type="button"
          aria-label="See my nfts"
          className={styles.TabButton}
          style={toggleBoard ? { color: '#cc7667' } : { color: '#dedede' }}
          onClick={showMyNFTs}
        >
          My NFTs
        </button>
        <button
          type="button"
          aria-label="See global scoreboard of all fighters"
          className={styles.TabButton}
          onClick={showAllFighters}
          style={toggleBoard ? { color: '#dedede' } : { color: '#cc7667' }}
          // eslint-disable-next-line
          autoFocus
        >
          All fighters
        </button>
      </div>
      <div className={styles.HorizontalSeparator} />

      {toggleBoard ? (
        personalScoreboard ? (
          <section className={styles.BoardContainer}>
            <ScoreBoard scoreBoardDTO={personalScoreboard} displayAmount={10} />
          </section>
        ) : (
          <Loader />
        )
      ) : scoreboard ? (
        <section className={styles.BoardContainer}>
          <ScoreBoard scoreBoardDTO={scoreboard} displayAmount={10} isGlobal />
        </section>
      ) : (
        <Loader />
      )}
    </section>
  );
};

export default LeaderboardHome;
