import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/esm/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';

import { handlePurchase } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/reduxHooks';
import { changePassToConsume } from '../../redux/reducers/roomReducer/roomReducer';
import { APP } from '../../routes';
import styles from './PassCard.module.css';

interface PassCardProps {
  fightsAmount: string | number;
  tokenId?: string;
  passId: number;
  viewMode: boolean;
  remainingFights?: number;
}

/**
 * Pass card component. Props:
 * @param {string | number} fightsAmount amount of fights the pass can grant at maximum
 * @param {string?} tokenId token id of the pass
 * @param {number} passId id of the pass. Based on the fetch. They will be ordered in the order the contract sends the dto. From the smallest to the biggest pass
 * @param {boolean} viewMode bollean to change if the card needs to display the buy UI
 * @param { number} remainingFights remaining fights of the user. Fetched from the metadata of the pass
 */
const PassCard: React.FC<PassCardProps> = ({
  fightsAmount,
  passId,
  viewMode,
  tokenId,
  remainingFights,
}) => {
  PassCard.defaultProps = {
    tokenId: '',
    remainingFights: 0,
  };

  const { address } = useAppSelector(state => state.connect);
  const { passesPrices } = useAppSelector(state => state.nft);
  const { selectedPassId, selectedTokenId } = useAppSelector(
    state => state.room,
  );
  const [amountToBuy, setAmountToBuy] = useState(0);
  const [alreadySelected, setAlreadySelected] = useState(false);
  const [price, setPrice] = useState(0);
  const location = useLocation();

  const handleIncrease = () => {
    if (amountToBuy < 10) {
      setAmountToBuy(amountToBuy + 1);
    }
  };

  const handleDecrease = () => {
    if (amountToBuy > 0) {
      setAmountToBuy(amountToBuy - 1);
    }
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (passesPrices && passesPrices[passId]) {
      setPrice(passesPrices[passId]);
    }
  }, [passesPrices, passId]);

  /** Effect to show the selected visuals in the card if selected */
  useEffect(() => {
    if (
      passId === selectedPassId &&
      tokenId &&
      tokenId === String(selectedTokenId)
    ) {
      setAlreadySelected(true);
    }
  }, [selectedPassId, selectedTokenId, passId, tokenId]);

  const card = (
    <div
      className={
        location.pathname === APP.FIGHT
          ? styles.PassCardContainerClickable
          : styles.PassCardContainer
      }
      style={
        alreadySelected && location.pathname === APP.FIGHT
          ? { border: '.25rem solid #ffde53' }
          : undefined
      }
    >
      <span className={styles.Title}>Fight Pass</span>
      {viewMode && tokenId ? (
        <span className={styles.Subtitle}>
          {address ? `Token id: ${tokenId}` : ''}
        </span>
      ) : (
        <span className={styles.Subtitle}>
          {address ? `$Fight: ${price}` : ''}
        </span>
      )}

      <div className={styles.PassCardSubcontainer}>
        {viewMode && fightsAmount && (
          <span className={styles.ViewTotalFights}>
            {remainingFights} / {fightsAmount}
          </span>
        )}
        {!viewMode && passesPrices && price > 0 && fightsAmount && (
          <span className={styles.Description}>{fightsAmount} FIGHTS</span>
        )}
        {!viewMode && price > 0 && (
          <div className={styles.PassCardCheckout}>
            <div className={styles.PassCardAmount}>
              <button
                type="button"
                aria-label="Decrease amount of this pass to buy"
                onClick={handleDecrease}
              >
                -
              </button>
              <div className={styles.AmountText}>{amountToBuy}</div>
              <button
                type="button"
                aria-label="Increase amount of this pass to buy"
                onClick={handleIncrease}
              >
                +
              </button>
            </div>
            <LinkContainer to={APP.BUY} className={styles.BuyButton}>
              <Nav.Link
                className={styles.PassCardButton}
                onClick={() => {
                  handlePurchase(
                    amountToBuy,
                    3,
                    dispatch,
                    undefined,
                    undefined,
                    passId,
                    undefined,
                  );
                }}
              >
                Buy
              </Nav.Link>
            </LinkContainer>
          </div>
        )}
      </div>
    </div>
  );

  const render: JSX.Element =
    location.pathname === APP.FIGHT ? (
      <button
        style={{ background: 'none', border: 'none' }}
        type="button"
        aria-label="Select this pass to consume"
        onClick={() => {
          if (viewMode && remainingFights && remainingFights > 0) {
            dispatch(
              changePassToConsume({
                selectedPassId: passId,
                selectedTokenId: Number(tokenId),
              }),
            );
          }
        }}
      >
        {card}
      </button>
    ) : (
      card
    );
  return render;
};

export default PassCard;
