import React from 'react';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications-component/dist/theme.css';
import './index.css';
import { createRoot } from 'react-dom/client';
import { ReactNotifications } from 'react-notifications-component';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store/store';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY || '',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.3,
});

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactNotifications />
      <App />
    </Provider>
  </React.StrictMode>,
);

reportWebVitals(console.log);
