import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Buy from './screens/Buy/Buy';

import Game from './screens/Game/Game';
import Landing from './screens/Landing/Landing';
import Marketplace from './screens/Marketplace/Marketplace';
import MyNFTs from './screens/MyNFTs/MyNFTs';
import Leaderboard from './screens/Leaderboard/Leaderboard';
import { APP } from './routes';

const App = () => (
  <BrowserRouter>
    <Routes>
      {process.env.REACT_APP_MARKETPLACE_ACTIVE === 'true' && (
        <Route path={APP.BUY} element={<Buy />} />
      )}

      {process.env.REACT_APP_MARKETPLACE_ACTIVE === 'true' && (
        <Route path={APP.MARKETPLACE} element={<Marketplace />} />
      )}

      {process.env.REACT_APP_FIGHT_ACTIVE === 'true' && (
        <Route path={APP.FIGHT} element={<Game />} />
      )}

      <Route path={APP.LEADERBOARD} element={<Leaderboard />} />

      {process.env.REACT_APP_MARKETPLACE_ACTIVE === 'true' && (
        <Route path={APP.MYNFTS} element={<MyNFTs />} />
      )}

      <Route path="/" element={<Landing />} />
    </Routes>
  </BrowserRouter>
);

export default App;
