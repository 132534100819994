import React from 'react';
import { Nav } from 'react-bootstrap/esm';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';

import styles from './GreenButton.module.css';
import { useAppSelector } from '../../redux/hooks/reduxHooks';
import { APP } from '../../routes';

interface GreenButtonProps {
  route: string;
  text: string;
}

/**
 * Green button component to redirect the user throughout all the available screens in the SPA dApp. Props:
 * @param {string} route route to redirect
 * @param {string} text text to display in button
 */
const GreenButton: React.FC<GreenButtonProps> = ({ route, text }) => {
  const { playing } = useAppSelector(state => state.room);
  const location = useLocation();

  return (
    <Nav.Item
      className={
        text === 'FIGHT' && location.pathname === APP.FIGHT
          ? styles.GreenButtonInactive
          : styles.GreenButton
      }
    >
      <LinkContainer
        to={route}
        style={{
          cursor:
            playing || (text === 'FIGHT' && location.pathname === APP.FIGHT)
              ? 'not-allowed'
              : '',
        }}
      >
        <Nav.Link className={styles.GreenButtonText} bsPrefix="Element">
          {text}
        </Nav.Link>
      </LinkContainer>
    </Nav.Item>
  );
};
export default GreenButton;
