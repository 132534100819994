import React, { useEffect, useState } from 'react';

import Header from '../../components/Header/Header';
import LoaderViewport from '../../components/LoaderViewport/LoaderViewPort';
import { GameHome } from '../../sections';

const Game: React.FC = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoaded(true), 500); // Wait before render to avoid timing problems
  }, []);
  return (
    <>
      <LoaderViewport />
      {loaded && (
        <>
          <Header />
          <GameHome />
        </>
      )}
    </>
  );
};

export default Game;
