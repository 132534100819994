import React from 'react';
import { Parallax, Background } from 'react-parallax';
import { AnimatedOnScroll } from 'react-animated-css-onscroll';

import { Timer } from '../../../components';
import { timerEnds } from '../../../config';
import styles from './Launch.module.css';

const Launch: React.FC = () => (
  <Parallax blur={10} strength={600}>
    <Background className={styles.ParallaxBG}>
      <img
        src="assets/Landing/Launch/LincolnPreparacion.png"
        className={styles.BackgroundBack}
        alt=""
      />
      <img
        src="assets/Landing/Launch/LincolnLanzamiento.gif"
        className={styles.BackgroundFront}
        alt=""
      />
    </Background>
    <section className={styles.LaunchContainer}>
      <AnimatedOnScroll
        animationIn="fadeInUp"
        animateOnce
        animationInDelay={100}
      >
        <section className={styles.TopContainer}>
          <img
            alt=""
            className={styles.TopLine}
            src="assets/Landing/Launch/LeftLine.svg"
          />
          <div className={styles.CounterContainer}>
            <div className={styles.CounterTitle}>Fights begin in</div>
            <div className={styles.CounterTimer}>
              <Timer EndDate={timerEnds} />
              <div className={styles.TimerText}>
                <div> hours </div>
                <div> minutes </div>
                <div> seconds</div>
              </div>
            </div>
          </div>
          <img
            alt=""
            className={styles.TopLine}
            src="assets/Landing/Launch/RightLine.svg"
          />
        </section>
      </AnimatedOnScroll>
      <AnimatedOnScroll
        animationIn="fadeInRight"
        animateOnce
        animationInDelay={100}
      >
        <section className={styles.CounterDescription}>
          <img
            alt=""
            className={styles.Line}
            src="assets/Landing/Launch/Line.svg"
          />
            The Retro Fighter for the Web3 Age
          <img
            alt=""
            className={styles.Line}
            src="assets/Landing/Launch/Line.svg"
          />
        </section>
      </AnimatedOnScroll>

      <section className={styles.SubContainer}>
        <img
          src="assets/Landing/Launch/flame-breath.gif"
          className={styles.Lincoln}
          alt=""
        />

        <div className={styles.Features}>
          <AnimatedOnScroll
            animationIn="fadeInRight"
            animateOnce
            animationInDelay={100}
          >
            <div className={styles.FeatureItem}>
              <img src="assets/Landing/Launch/Heart.png" alt="" />
              <p className={styles.FeatureText}>
                0xMen is a web3 powered retro-fighter powered by a collection of 3333 NFTs featuring 75 unique playable characters.
              </p>
            </div>
          </AnimatedOnScroll>
          <AnimatedOnScroll
            animationIn="fadeInRight"
            animateOnce
            animationInDelay={125}
          >
            <div className={styles.FeatureItem}>
              <img src="assets/Landing/Launch/Heart.png" alt="" />
              <p className={styles.FeatureText}>
                Compete daily to earn a share of $FIGHT reward token proportional to your ranking on the 0xmen leaderboard.
              </p>
            </div>
          </AnimatedOnScroll>
          <AnimatedOnScroll
            animationIn="fadeInRight"
            animateOnce
            animationInDelay={150}
          >
            <div className={styles.FeatureItem}>
              <img src="assets/Landing/Launch/Heart.png" alt="" />
              <p className={styles.FeatureText}>
                Purchase fight passes, in-game taunts and premium fighting arenas via the marketplace using the native $FIGHT token.
              </p>
            </div>
          </AnimatedOnScroll>
        </div>
      </section>
    </section>
  </Parallax>
);

export default Launch;
