import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import { Store } from 'react-notifications-component';

import { delayAlerts, contractAddresses } from '../../../config';
import { CheckoutItem } from '../../../lib/types';
import passMinterAbi from '../../../abi/PassMinter.json';
import extraMinterAbi from '../../../abi/ExtrasMinter.json';
import characterMinterAbi from '../../../abi/CharacterMinter.json';
import { initNFT } from '../nftReducer/nftReducer';
import { fetchBalance } from '../tokenReducer/tokenReducer';
import { fetchETH } from '../connectReducer/connectReducer';
import { APP } from '../../../routes';

/**
 * Fetches the allowance of minter address from Fight toke
 */
export const fetchAllowance = createAsyncThunk(
  'fetchAllowance',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action, thunkAPI: any) => {
    try {
      const { address } = thunkAPI.getState().connect;
      const { tokenInstance } = thunkAPI.getState().token;
      const passMinterAddress = contractAddresses.PassMinter;
      const passAllowanceBn = await tokenInstance.allowance(
        address,
        passMinterAddress,
      );

      const passAllowance = Number(ethers.utils.formatEther(passAllowanceBn));

      const extrasAddress = contractAddresses.ExtrasMinter;
      const extrasAllowanceBn = await tokenInstance.allowance(
        address,
        extrasAddress,
      );

      const extrasAllowance = Number(
        ethers.utils.formatEther(extrasAllowanceBn),
      );

      return { passAllowance, extrasAllowance };
    } catch (error) {
      console.log('Error fetching allowance', error);
      Promise.resolve(setTimeout(() => {}, 1000));
      thunkAPI.dispatch(fetchAllowance());
      throw error;
    }
  },
);

/**
 * Thunk to refetch user data after a successful transaction
 */
export const successfulRefetch = createAsyncThunk(
  'successRefetch',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action, thunkAPI: any) => {
    try {
      thunkAPI.dispatch(initNFT({ withoutMarket: true }));
      thunkAPI.dispatch(fetchBalance());
      thunkAPI.dispatch(fetchAllowance());
      thunkAPI.dispatch(fetchETH());
    } catch (error) {
      console.log('Error refetching user data', error);
      throw error;
    }
  },
);

/**
 * Thunk to purchase the amount of the desired nft.
 * All params are passed inside an object of type CheckouItem
 * @param {number} id id of the pass to purchase
 * @param {number} amount of the pass to purchase
 */
export const purchasePasses = createAsyncThunk(
  'purchasePasses',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action: CheckoutItem, thunkAPI: any) => {
    try {
      const { signer, address, provider } = thunkAPI.getState().connect;
      const minterAddress = contractAddresses.PassMinter;
      const minterInstance = new ethers.Contract(
        minterAddress,
        passMinterAbi,
        signer,
      );
      const tx = await minterInstance.purchase(
        address,
        action.id,
        action.amount,
      );
      await provider.waitForTransaction(tx.hash);
      thunkAPI.dispatch(successfulRefetch());
    } catch (error) {
      console.log('Error purchasing passes', error);
      throw error;
    }
  },
);

/**
 * Thunk to approves the amount of $FIGHT needed for buy the desired checkout.
 * All params are passed inside an object, as properties
 * @param {number} cost cost of the pass approve
 */
export const approvePasses = createAsyncThunk(
  'approvePasses',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action, thunkAPI: any) => {
    try {
      const { provider } = thunkAPI.getState().connect;
      const { tokenSignerInstance } = thunkAPI.getState().token;
      const minterAddress = contractAddresses.PassMinter;

      const tx: ethers.Transaction = await tokenSignerInstance.approve(
        minterAddress,
        ethers.constants.MaxUint256,
      );

      await provider.waitForTransaction(tx.hash);
      thunkAPI.dispatch(successfulRefetch());
    } catch (error) {
      console.log('Error approving passes', error);
      throw error;
    }
  },
);

/**
 * Purchase and mints the desired amount of character.
 * All params are passed inside an object, as properties
 * @param {number} amount the desired amount of the character.
 * @param {number} totalPrice the total price of the characters to mint.
 */
export const purchaseCharacter = createAsyncThunk(
  'purchaseCharacter',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action: { totalPrice: number; amount: number }, thunkAPI: any) => {
    try {
      const { signer, address, provider } = thunkAPI.getState().connect;
      const minterAddress = contractAddresses.CharacterMinter;
      const minterInstance = new ethers.Contract(
        minterAddress,
        characterMinterAbi,
        signer,
      );

      const tx = await minterInstance.purchase(address, action.amount, {
        value: ethers.utils.parseUnits(action.totalPrice.toString()),
      });
      await provider.waitForTransaction(tx.hash);
      thunkAPI.dispatch(successfulRefetch());
    } catch (error) {
      console.log('Error purchasing character', error);
      throw error;
    }
  },
);

/**
 * Purchases the amount of the desired extra.
 * All params are passed inside an object as properties
 * @param {number} id Id of the pass to purchase
 * @param {number} amount Amount of the pass to purchase
 * @param {boolean} isAudio If true the tx will be for buy an audio. If false it will buy a background
 */
export const purchaseExtras = createAsyncThunk(
  'purchaseExtras',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action: CheckoutItem & { isAudio: boolean }, thunkAPI: any) => {
    try {
      const { signer, address, provider } = thunkAPI.getState().connect;
      const minterAddress = contractAddresses.ExtrasMinter;
      const minterInstance = new ethers.Contract(
        minterAddress,
        extraMinterAbi,
        signer,
      );

      const tx = await minterInstance.purchase(
        address,
        action.isAudio
          ? contractAddresses.AudioTaunt
          : contractAddresses.Background,
        action.id,
        action.amount,
      );
      await provider.waitForTransaction(tx.hash);
      thunkAPI.dispatch(successfulRefetch());
    } catch (error) {
      console.log('Error purchasing extras', error);
      throw error;
    }
  },
);

/**
 * Approves the amount of $FIGHT needed for buy the desired extra.
 * All params are passed wrapped inside an object as properties
 * @param {number} cost Cost of approving the extra
 */
export const approveExtras = createAsyncThunk(
  'approveExtras',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (action, thunkAPI: any) => {
    try {
      const { provider } = thunkAPI.getState().connect;
      const { tokenSignerInstance } = thunkAPI.getState().token;
      const minterAddress = contractAddresses.ExtrasMinter;

      const tx: ethers.Transaction = await tokenSignerInstance.approve(
        minterAddress,
        ethers.constants.MaxUint256,
      );
      await provider.waitForTransaction(tx.hash);
      thunkAPI.dispatch(successfulRefetch());
    } catch (error) {
      console.log('Error approving passes', error);
      throw error;
    }
  },
);

export type ConnectState = {
  extrasAllowance: number;
  passAllowance: number;
  rejected: number;
  successful: number;
};

const initialMinterState: ConnectState = {
  extrasAllowance: 0,
  passAllowance: 0,
  rejected: 0,
  successful: 0,
};

const minterSlice = createSlice({
  name: 'minterReducer',
  initialState: initialMinterState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(purchasePasses.fulfilled, state => {
        console.log('You have purchased your NFT. Go to MyNfts to see it');
        state.successful += 1;

        Store.addNotification({
          title: 'Purchase',
          message: 'You have purchased your NFT. Go to MyNfts to see it',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: delayAlerts,
            onScreen: true,
          },
          onRemoval: () => {
            const urlOrigin = window.location.origin;
            window.location.assign(urlOrigin + APP.MYNFTS);
          },
        });
      })
      .addCase(purchasePasses.rejected, state => {
        console.error('Transaction failed');
        state.rejected += 1;

        Store.addNotification({
          title: 'Purchase',
          message: 'Error purchasing',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: delayAlerts,
            onScreen: true,
          },
        });
      })
      .addCase(purchaseCharacter.fulfilled, state => {
        console.log('Transaction succesfully sended');
        state.successful += 1;

        Store.addNotification({
          title: 'Purchase',
          message: 'Succesful character mint',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: delayAlerts,
            onScreen: true,
          },
          onRemoval: () => {
            const urlOrigin = window.location.origin;
            window.location.assign(urlOrigin + APP.MYNFTS);
          },
        });
      })
      .addCase(purchaseCharacter.rejected, state => {
        console.error('Transaction failed');
        state.rejected += 1;

        Store.addNotification({
          title: 'Purchase',
          message: 'Error minting character',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: delayAlerts,
            onScreen: true,
          },
        });
      })
      .addCase(purchaseExtras.fulfilled, state => {
        console.error('You have purchased your NFT. Go to MyNfts to see it');
        state.successful += 1;

        Store.addNotification({
          title: 'Purchase',
          message: 'You have purchased your NFT. Go to MyNfts to see it',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: delayAlerts,
            onScreen: true,
          },
          onRemoval: () => {
            const urlOrigin = window.location.origin;
            window.location.assign(urlOrigin + APP.MYNFTS);
          },
        });
      })
      .addCase(purchaseExtras.rejected, state => {
        console.error('Transaction failed');
        state.rejected += 1;

        Store.addNotification({
          title: 'Purchase',
          message: 'Error purchasing',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: delayAlerts,
            onScreen: true,
          },
        });
      })
      .addCase(approvePasses.fulfilled, state => {
        console.log('You have approved Fight. Please now purchase your NFT');
        state.successful += 1;

        Store.addNotification({
          title: 'Purchase',
          message: 'You have approved Fight. Please now purchase your NFT',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: delayAlerts,
            onScreen: true,
          },
        });
      })
      .addCase(approvePasses.rejected, state => {
        console.error('Transaction failed');
        state.rejected += 1;

        Store.addNotification({
          title: 'Purchase',
          message: 'Error approving amount',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: delayAlerts,
            onScreen: true,
          },
        });
      })
      .addCase(approveExtras.fulfilled, state => {
        console.log('You have approved Fight. Please now purchase your NFT');
        state.successful += 1;

        Store.addNotification({
          title: 'Purchase',
          message: 'You have approved Fight. Please now purchase your NFT',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: delayAlerts,
            onScreen: true,
          },
        });
      })
      .addCase(approveExtras.rejected, state => {
        console.error('Transaction failed');
        state.rejected += 1;

        Store.addNotification({
          title: 'Purchase',
          message: 'Error approving amount',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: delayAlerts,
            onScreen: true,
          },
        });
      })
      .addCase(fetchAllowance.fulfilled, (state, action) => {
        state.passAllowance = action.payload.passAllowance;
        state.extrasAllowance = action.payload.extrasAllowance;
      })
      .addCase(fetchAllowance.rejected, () => {
        console.error('Failed to fetch allowance, check config and connection');
      });
  },
});

export const minterReducer = minterSlice.reducer;
