import React, { useCallback, useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';
import { Container, Dropdown, Modal } from 'react-bootstrap/esm';

import styles from './Header.module.css';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/reduxHooks';
import {
  checkChain,
  checkConnection,
  connectWallet,
  disconnectWallet,
  fetchETH,
  initProvider,
  setCurrentChainId,
  setUserFetched,
  startConnection,
  stopConnection,
} from '../../redux/reducers/connectReducer/connectReducer';
import { initToken } from '../../redux/reducers/tokenReducer/tokenReducer';
import { APP } from '../../routes';
import GreenButton from '../GreenButton/GreenButton';
import { contractAddresses, networkToCheck } from '../../config';
import {
  fetchRewards,
  fetchPersonalScoreboard,
  initApi,
  fetchGlobalScoreboard,
  fetchTopScoreboard,
} from '../../redux/reducers/apiReducer/apiReducer';
import NotificationModal from '../NotificationModal/NotificationModal';
import { fetchAllowance } from '../../redux/reducers/minterReducer/minterReducer';
import { setPlaying } from '../../redux/reducers/roomReducer/roomReducer';
import {
  fetchCharacterSupply,
  initNFT,
} from '../../redux/reducers/nftReducer/nftReducer';
import { fetchConsoles } from '../../redux/reducers/freeMintReducer/freeMintReducer';

export function getWindowDimensions() {
  const { innerWidth: width } = window;
  return width;
}

/**
 * Component to render the header throught all the screens available in the SPA dApp.
 * Depending if the user is in the home or not the header will display a different style
 */
const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const { connecting, address, provider, userFetched, currentChainId } =
    useAppSelector(state => state.connect);
  const { playing } = useAppSelector(state => state.room); // if player its currently playing
  const { balance, tokenInstance } = useAppSelector(state => state.token);
  const { rewards, axiosInstance } = useAppSelector(state => state.api);
  const [size, setSize] = useState(getWindowDimensions());
  const [navOpen, setNavOpen] = useState(false); // toggle nav in tablet or mobile
  const [userDataOpen, setUserDataOpen] = useState(false); // togle dropdown of user data
  const [claim, setClaim] = useState(false); // if the cookie its defined with true , then cannot claim
  const [rewardsClaimed, setRewardsClaimed] = useState(false);
  const [rewardsDisplayed, setRewardsDisplayed] = useState('');
  const [balanceDisplayed, setBalanceDisplayed] = useState('');
  const [currentY, setCurrentY] = useState(window.scrollY);

  const handleShow = () => dispatch(startConnection());
  const handleClose = () => dispatch(stopConnection());
  const handleClaim = () => setClaim(false);
  const location = useLocation();
  /** Handle user scroll and update an state to check this */
  const handleScroll = () => {
    const position = window.pageYOffset;
    setCurrentY(position);
  };

  /** Dispatchs the reducers to start contracts and get user data */
  const dispatchReducers = useCallback(async () => {
    if (
      provider &&
      address &&
      axiosInstance &&
      currentChainId === Number(networkToCheck.chainId)
    ) {
      dispatch(initToken());
      dispatch(initNFT({}));
      dispatch(fetchETH());
      dispatch(fetchRewards());
      dispatch(fetchPersonalScoreboard());

      if (process.env.REACT_APP_CLAIM_ACTIVE === 'true') {
        dispatch(fetchConsoles());
      }
    }
    // eslint-disable-next-line
  }, [dispatch, address]);

  /** Save the current chain to compare against the desired one. */
  useEffect(() => {
    if (provider) {
      const setNetwork = async () => {
        const providerNetwork = await provider.getNetwork();
        setCurrentChainId(providerNetwork.chainId);
      };
      setNetwork();
    }
  }, [provider]);

  /** Effect to inits provider, checks if can connect to metamask. Fetch global and top scoreboard
   * Non user account related data
   */

  useEffect(() => {
    if (!userFetched) {
      dispatch(initApi());
      dispatch(initProvider());
      dispatch(checkConnection());
    }
    function handleResize() {
      setSize(getWindowDimensions());
    }

    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };

    // eslint-disable-next-line
  }, []);

  /** Format rewards in a better display string */
  useEffect(() => {
    const strRewards = rewards.toString();
    if (rewards >= 1 && strRewards.includes('.')) {
      const splittedReward: string[] = strRewards.split('.');
      setRewardsDisplayed(
        `${splittedReward[0]}.${splittedReward[1].slice(0, 3)}`,
      );
    } else if (strRewards.length > 5) {
      setRewardsDisplayed(`${strRewards.slice(0, 4)}`);
    } else {
      setRewardsDisplayed(strRewards);
    }
  }, [rewards]);

  /** Format balance in a better display string, if the balance changes check if claimed */
  useEffect(() => {
    const claimCookie = document.cookie.replace(
      /(?:(?:^|.*;\s*)rewardClaim\s*=\s*([^;]*).*$)|^.*$/,
      '$1',
    );
    setRewardsClaimed(claimCookie === 'true');
    const strBalance = balance.toString();
    if (balance >= 1 && strBalance.includes('.')) {
      const splittedBalance: string[] = strBalance.split('.');
      setBalanceDisplayed(
        `${splittedBalance[0]}.${splittedBalance[1].slice(0, 3)}`,
      );
    } else if (strBalance.length > 5) {
      setBalanceDisplayed(`${strBalance.slice(0, 4)}`);
    } else {
      setBalanceDisplayed(strBalance);
    }
  }, [balance]);

  /** Effect to load web data that depends on the provider being created first and even before fetching ana address
   * Non user account related data
   */
  useEffect(() => {
    if (provider) {
      dispatch(fetchCharacterSupply());
    }
  }, [provider, dispatch]);

  /** Effect to load web data that depends on the provider being created first and even before fetching ana address
   * Non user account related data
   */
  useEffect(() => {
    if (axiosInstance) {
      dispatch(fetchGlobalScoreboard());
      dispatch(fetchTopScoreboard());
    }
  }, [axiosInstance, dispatch]);

  /** Effect to fetch user data. set the fetch to true
   * User related data
   */
  useEffect(() => {
    if (!userFetched) {
      dispatchReducers();
    }
    // eslint-disable-next-line
  }, [dispatch, dispatchReducers]);

  /** Effect to disconnect wallet if the account or chain changes. */
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('chainChanged', (chainId: string) => {
        if (userFetched) dispatch(setUserFetched({ set: false }));
        if (chainId !== networkToCheck.chainId) {
          checkChain();
          dispatch(disconnectWallet());
        } else {
          // if the user goes back to the working chain reload the website to avoid errors
          document.location.reload();
        }
      });

      window.ethereum.on('accountsChanged', () => {
        document.location.reload();
      });
    }
  }, [dispatch, userFetched]);

  /** Effect to fetch allowance of the user when the address changes */
  useEffect(() => {
    if (address && tokenInstance && !userFetched) {
      /** fetch user allowance */
      dispatch(fetchAllowance());
    }
    // eslint-disable-next-line
  }, [dispatch, address, tokenInstance]);

  /** Effect to go up when changing screens. It also sets playing false if true and inside other screen that is not FIGHT */
  useEffect(() => {
    if (location.pathname === APP.FIGHT && playing) return;
    window.scrollTo(0, 0);

    if (playing && location.pathname !== APP.FIGHT) {
      dispatch(setPlaying({ playing: false }));
    }
  }, [location.pathname, playing, dispatch]);

  const userDataNav = (
    <>
      {address && rewards > 0.01 && !rewardsClaimed && (
        <Nav.Link
          style={{
            cursor: playing ? 'not-allowed' : '',
          }}
          className={styles.RewardsElement}
          onClick={() => {
            if (!playing) setClaim(true);
          }}
        >
          CLAIM ${rewardsDisplayed} FIGHT
        </Nav.Link>
      )}
      {address && (
        <Nav.Link
          className={styles.BalanceElement}
          style={{
            cursor: playing ? 'not-allowed' : '',
          }}
          href={
            playing
              ? ''
              : `${networkToCheck.blockExplorerUrls}address/${contractAddresses.Fight}`
          }
          target="blank"
        >
          <img src="assets/Header/Coin.svg" alt="" />
          $FIGHT: {balanceDisplayed}
        </Nav.Link>
      )}
      {address ? (
        <div className={styles.AddressRow}>
          <span className={styles.Address}>
            {address && `${address.slice(0, 4)}...${address.slice(40, 42)}`}
          </span>
          <Nav.Link
            style={{
              cursor: playing ? 'not-allowed' : '',
            }}
            onClick={() => {
              if (!playing && userFetched) {
                dispatch(disconnectWallet());
                dispatch(setUserFetched({ set: false }));
              }
            }}
            className={styles.Element}
          >
            <img
              className={styles.Logout}
              src="assets/Header/Logout.png"
              alt=""
            />
          </Nav.Link>
        </div>
      ) : (
        <Nav.Link className={styles.Element} onClick={handleShow}>
          LOGIN
        </Nav.Link>
      )}
    </>
  );

  const navContent = (
    <Nav className={`${styles.Nav} mr-auto`}>
      {(currentY > 600 || location.pathname !== APP.LANDING) &&
        process.env.REACT_APP_FIGHT_ACTIVE === 'true' && (
        <Nav.Item bsPrefix="Element">
          <GreenButton route={APP.FIGHT} text="FIGHT" />
        </Nav.Item>
      )}
      {address && process.env.REACT_APP_MARKETPLACE_ACTIVE === 'true' && (
        <Nav.Item bsPrefix="Element">
          <LinkContainer
            style={{
              cursor: playing ? 'not-allowed' : '',
            }}
            to={playing ? APP.FIGHT : APP.MYNFTS}
          >
            <Nav.Link
              className={
                location.pathname === APP.MYNFTS
                  ? styles.SelectedElement
                  : styles.Element
              }
            >
              MY NFTs
            </Nav.Link>
          </LinkContainer>
        </Nav.Item>
      )}
      <Nav.Item bsPrefix="Element">
        <LinkContainer
          style={{
            cursor: playing ? 'not-allowed' : '',
          }}
          to={playing ? APP.FIGHT : APP.LEADERBOARD}
        >
          <Nav.Link
            className={
              location.pathname === APP.LEADERBOARD
                ? styles.SelectedElement
                : styles.Element
            }
          >
            LEADERBOARD
          </Nav.Link>
        </LinkContainer>
      </Nav.Item>
      {process.env.REACT_APP_MARKETPLACE_ACTIVE === 'true' && (
        <Nav.Item bsPrefix="Element">
          <LinkContainer
            style={{
              cursor: playing ? 'not-allowed' : '',
            }}
            to={playing ? APP.FIGHT : APP.MARKETPLACE}
          >
            <Nav.Link
              className={
                location.pathname === APP.MARKETPLACE
                  ? styles.SelectedElement
                  : styles.Element
              }
            >
              BUY NFT
            </Nav.Link>
          </LinkContainer>
        </Nav.Item>
      )}
      {size >= 951 ? (
        <Dropdown className={styles.Drop}>
          <Dropdown.Toggle
            className={styles.Toggle}
            variant=""
            onClick={() => {
              setUserDataOpen(!userDataOpen);
            }}
          >
            {userDataOpen ? (
              <img
                className={styles.Profile}
                src="assets/Header/Profile icon selected.gif"
                alt=""
              />
            ) : (
              <img
                className={styles.Profile}
                src="assets/Header/Profile icon.gif"
                alt=""
              />
            )}
          </Dropdown.Toggle>
          {userDataOpen && (
            <Dropdown.Menu
              onMouseLeave={() => setUserDataOpen(false)}
              show={userDataOpen}
              align="end"
              className={styles.Menu}
            >
              {userDataNav}
            </Dropdown.Menu>
          )}
        </Dropdown>
      ) : (
        userDataNav
      )}
    </Nav>
  );

  return (
    <header
      style={{
        filter: playing ? 'grayscale(1)' : '',
        cursor: playing ? 'not-allowed' : '',
      }}
    >
      <Navbar
        className={
          location.pathname === '/' ? styles.LightNavbar : styles.DarkNavbar
        }
        style={{ paddingRight: '0' }}
        collapseOnSelect
        fixed={location.pathname === '/' ? 'top' : undefined}
        expand="lg"
        variant=""
      >
        {claim && (
          <Modal
            contentClassName={styles.ModalContent}
            show={claim}
            centered
            size="lg"
            animation
            onHide={handleClaim}
            onClose={handleClaim}
          >
            <NotificationModal
              message={`You can claim your reward of ${rewardsDisplayed} $FIGHT`}
              onBack={handleClaim}
            />
          </Modal>
        )}

        {!claim && (
          <Modal
            contentClassName={styles.ModalContent}
            show={connecting}
            centered
            size="lg"
            animation
            onHide={handleClose}
            onClose={handleClose}
          >
            <button
              className={styles.ConnectButton}
              type="button"
              aria-label="Login with WalletConnect"
              onClick={() => {
                dispatch(connectWallet({ isWalletConnect: true }));
              }}
            >
              <img
                className={styles.LoginLogo}
                src="assets/Components/Header/walletconnect.svg"
                alt=""
              />
              WalletConnect
            </button>
            <button
              className={styles.ConnectButton}
              type="button"
              aria-label="Login with Metamask"
              onClick={() => {
                dispatch(connectWallet({ isWalletConnect: false }));
              }}
            >
              <img
                className={styles.LoginLogo}
                src="assets/Components/Header/metamask.svg"
                alt=""
              />
              Metamask
            </button>
          </Modal>
        )}
        <Container className={styles.Container}>
          <LinkContainer
            to={playing ? APP.FIGHT : APP.LANDING}
            style={{
              cursor: playing ? 'not-allowed' : '',
            }}
          >
            <img className={styles.Logo} src="assets/Logo.png" alt="" />
          </LinkContainer>
          {size < 951 ? (
            <>
              <button
                type="button"
                className={styles.Button}
                onClick={() => setNavOpen(!navOpen)}
                aria-expanded={navOpen}
                aria-label="Open navbar dropdown"
              >
                {navOpen ? (
                  <img src="assets/Header/Cross.svg" alt="" />
                ) : (
                  <img src="assets/Header/Burger.svg" alt="" />
                )}
              </button>
              <Navbar.Collapse in={navOpen}>{navContent}</Navbar.Collapse>
            </>
          ) : null}

          {size >= 951 ? navContent : null}
        </Container>
      </Navbar>
    </header>
  );
};
export default Header;
