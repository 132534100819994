import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/esm/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';

import { CarouselWrap, NotificationModal } from '../../components';
import CustomSelector from '../../components/CustomSelector/CustomSelector';
import Loader from '../../components/Loader/Loader';
import { delayPageLoad, openseaCollection } from '../../config';
import { handlePurchase } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/reduxHooks';
import { APP } from '../../routes';
import styles from './MarketHome.module.css';
import { AudioDTO } from '../../lib/types';

const GameHome: React.FC = () => {
  const { address, userFetched } = useAppSelector(state => state.connect);
  const { audiosToSell, backgroundsToSell } = useAppSelector(
    state => state.nft,
  );
  const { passesPrices } = useAppSelector(state => state.nft);
  const { merkleClaims, normalClaims, genesisClaims } = useAppSelector(
    state => state.freeMint,
  );
  const [audiosToDisplay, setAudiosToDisplay] = useState<AudioDTO[]>([]); // Audios filtered by character to display
  const [selected, setSelected] = useState('Alexander The Wonderful'); // Selected character, Alexander by default
  const [availableClaims, setAvailableClaims] = useState(0); // Character claims available to claim
  const [nameList, setNameList] = useState<string[]>([]); // Character list to build the audio selector
  const dispatch = useAppDispatch();
  const location = useLocation();

  const handleSelector = (value: string) => {
    setSelected(value);
  };

  /** Effect to scroll down to the choosen asset */
  useEffect(() => {
    if (userFetched) {
      switch (location.hash) {
      case '#backgrounds': {
        const elem = document.getElementById('backgrounds');
        if (elem) {
          setTimeout(() => {
            elem.scrollIntoView();
          }, delayPageLoad + 100);
        }
        break;
      }
      case '#audiotaunts': {
        const elem = document.getElementById('audiotaunts');
        if (elem) {
          setTimeout(() => {
            elem.scrollIntoView();
          }, delayPageLoad + 100);
        }
        break;
      }
      case '#passes': {
        const elem = document.getElementById('passes');
        if (elem) {
          setTimeout(() => {
            elem.scrollIntoView();
          }, delayPageLoad + 100);
        }
        break;
      }
      default: {
        break;
      }
      }
    }
  }, [userFetched, location.hash]);

  /** Effect to filter the audios to display among all audios to sell */
  useEffect(() => {
    if (audiosToSell) {
      const selectedAudiosDTO: AudioDTO[] | null = [];

      audiosToSell.forEach(element => {
        const newList = nameList;
        const alreadyInside = newList.find(
          name => name === element.characterName,
        );
        if (
          alreadyInside === undefined &&
          element.characterName &&
          element.characterName.length > 0
        ) {
          newList.push(element.characterName);
        }

        setNameList(newList);

        if (selected.length === 0) {
          selectedAudiosDTO.push(element);
        } else if (element.characterName === selected) {
          selectedAudiosDTO.push(element);
        }
      });
      setAudiosToDisplay(selectedAudiosDTO);
    }
  }, [audiosToSell, selected, nameList]);

  /**
   * Effect to get the pending clais in any single available claim. If more than 0 it will display the claim section at the end
   */
  useEffect(() => {
    const arrSum: number[] = [0];
    if (normalClaims && normalClaims.length > 0) {
      const arr = normalClaims.map(element => element.pendingClaims);
      arrSum.push(arr.reduce((a, b) => a + b));
    }
    if (genesisClaims && genesisClaims.length > 0) {
      const arr = genesisClaims.map(element => element.pendingClaims);
      arrSum.push(arr.reduce((a, b) => a + b));
    }
    if (merkleClaims) arrSum.push(1);

    const totalSum = arrSum.reduce((a, b) => a + b);

    setAvailableClaims(totalSum);
  }, [normalClaims, genesisClaims, merkleClaims]);

  return (
    <section className={styles.MarketHomeContainer}>
      {address ? (
        userFetched ? (
          <>
            {process.env.REACT_APP_CHARACTERS_ACTIVE === 'true' && (
              <>
                <div className={styles.BannerContainer}>
                  <img alt="" src="assets/Marketplace/Banner.png" />
                </div>
                <div className={styles.BuyRow}>
                  <LinkContainer to={APP.BUY} className={styles.BuyButton}>
                    <Nav.Link
                      onClick={() =>
                        handlePurchase(
                          1,
                          0,
                          dispatch,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                        )
                      }
                    >
                      Mint A Random Fighter
                    </Nav.Link>
                  </LinkContainer>
                  <a
                    className={styles.BuyButton}
                    href={
                      openseaCollection.collectionBaseUri +
                      openseaCollection.name
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    Buy Fighters
                  </a>
                </div>
              </>
            )}

            {process.env.REACT_APP_EXTRAS_ACTIVE === 'true' && (
              <>
                <span className={styles.Title} id="backgrounds">
                  Backgrounds
                </span>

                {backgroundsToSell && backgroundsToSell[0].background ? (
                  <div className={styles.Carousel}>
                    <CarouselWrap
                      dots
                      mode={1}
                      backgroundDTO={backgroundsToSell}
                    />
                  </div>
                ) : (
                  <Loader />
                )}
                <span className={styles.Title} id="audiotaunts">
                  Audio Taunts
                  <CustomSelector
                    selected={selected}
                    itemList={nameList}
                    onItemSelected={handleSelector}
                  />
                </span>
                <span className={styles.Subtitle}>
                  Only the audios of your characters will be available to use in
                  a match
                </span>
                {audiosToSell && audiosToSell[0].audioTrack ? (
                  <div className={styles.Carousel}>
                    <CarouselWrap
                      dots={false}
                      mode={2}
                      audioDTO={audiosToDisplay}
                    />
                  </div>
                ) : (
                  <Loader />
                )}

                <span className={styles.Title} id="passes">
                  Fight Passes
                </span>

                {passesPrices && passesPrices[0] ? (
                  <div className={styles.Carousel}>
                    <CarouselWrap dots={false} mode={3} />
                  </div>
                ) : (
                  <Loader />
                )}
              </>
            )}
            {process.env.REACT_APP_CLAIM_ACTIVE === 'true' &&
              availableClaims > 0 &&
              (merkleClaims === true ||
                (normalClaims && normalClaims.length > 0) ||
                (genesisClaims && genesisClaims.length > 0)) && (
              <>
                <span className={styles.Title} id="passes">
                    Free Character Claims
                </span>
                <div className={styles.Carousel}>
                  <CarouselWrap dots={false} mode={4} />
                </div>
              </>
            )}
          </>
        ) : (
          <Loader forScreen />
        )
      ) : (
        <NotificationModal
          message="To buy first connect your wallet"
          isConnect
        />
      )}
    </section>
  );
};

export default GameHome;
