import React, { useCallback, useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';

import { LinkContainer } from 'react-router-bootstrap';

import {
  useAppDispatch,
  useAppSelector,
} from '../../../redux/hooks/reduxHooks';
import {
  approveExtras,
  approvePasses,
  purchaseCharacter,
  purchaseExtras,
  purchasePasses,
} from '../../../redux/reducers/minterReducer/minterReducer';
import AudioCard from '../../../components/AudioCard/AudioCard';
import BackgroundCard from '../../../components/BackgroundCard/BackgroundCard';
import PassCard from '../../../components/PassCard/PassCard';
import styles from './BuyMode.module.css';
import { APP } from '../../../routes';
import Loader from '../../../components/Loader/Loader';
import { NotificationModal } from '../../../components';
import { fightPerId } from '../../../lib/dtos';

const BuyMode: React.FC = () => {
  const { address, balanceETH } = useAppSelector(state => state.connect);

  const { charactersSupply, passesPrices, charactersPrice } = useAppSelector(
    state => state.nft,
  );
  const { passAllowance, extrasAllowance, rejected, successful } =
    useAppSelector(state => state.minter);
  const {
    amount,
    mode,
    selectedExtraId,
    selectedPassId,
    backgroundData,
    audioData,
  } = useAppSelector(state => state.market);
  const { tokenInstance, balance } = useAppSelector(state => state.token);
  const [buyAmount, setBuyAmount] = useState(0);
  const [individualPrice, setIndividualPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [title, setTitle] = useState('');
  const [banner, setBanner] = useState(<div />); // Banner to display depending on the selected asset
  const [allowed, setAllowed] = useState(false); // Enough allowance boolean
  const [funded, setFunded] = useState(false); // Enough funds boolean
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(true); // Boolean to avoid multiple tx triggers
  const [totalSupply, setTotalSupply] = useState<number | null>(null);
  const [price] = useState(
    mode === 1 ? backgroundData?.price : audioData?.price,
  );
  const currencyText = mode === 0 ? '$ETH' : '$FIGHT';
  const dispatch = useAppDispatch();
  const MAX_SUPPLY = 10000; // Max supply limit, to set the mint button off

  const handleIncrease = () => {
    if (buyAmount < 10) {
      setBuyAmount(buyAmount + 1);
    }
  };

  const handleDecrease = () => {
    if (buyAmount > 0) {
      setBuyAmount(buyAmount - 1);
    }
  };

  /** check user balance */
  const checkBalance = useCallback(() => {
    if (balance > totalPrice) {
      setFunded(true);
    } else {
      setFunded(false);
    }
  }, [balance, totalPrice]);

  /** Dispatch the correct tx */
  const handleDispatch = () => {
    if (enabled && funded) {
      switch (mode) {
      case 0: {
        if (totalSupply && totalSupply < MAX_SUPPLY) {
          dispatch(
            purchaseCharacter({
              amount: buyAmount,
              totalPrice,
            }),
          );
          setLoading(true);
        }
        break;
      }
      case 1: {
        if (selectedExtraId !== null) {
          if (allowed) {
            dispatch(
              purchaseExtras({
                id: selectedExtraId,
                amount: buyAmount,
                isAudio: false,
              }),
            );
          } else {
            dispatch(approveExtras());
          }
          setLoading(true);
        }
        break;
      }
      case 2: {
        if (selectedExtraId !== null) {
          if (allowed) {
            dispatch(
              purchaseExtras({
                id: selectedExtraId,
                amount: buyAmount,
                isAudio: true,
              }),
            );
          } else {
            dispatch(approveExtras());
          }
          setLoading(true);
        }
        break;
      }
      case 3: {
        if (selectedPassId !== null) {
          if (allowed) {
            dispatch(
              purchasePasses({
                id: selectedPassId,
                amount: buyAmount,
              }),
            );
          } else {
            dispatch(approvePasses());
          }
          setLoading(true);
        }
        break;
      }
      default: {
        break;
      }
      }
    }

    setEnabled(false);
  };

  /** Avoid multiple click tx trigger */
  useEffect(() => {
    if (!enabled) setTimeout(() => setEnabled(true), 1000);
  }, [enabled]);

  useEffect(() => {
    if (address && tokenInstance) {
      setLoading(false);
    }
  }, [address, tokenInstance, rejected, successful]);

  useEffect(() => {
    setBuyAmount(amount);
  }, [amount]);

  useEffect(() => {
    switch (mode) {
    case 0: {
      if (charactersSupply) setTotalSupply(charactersSupply);
      break;
    }
    default: {
      break;
    }
    }
  }, [charactersSupply, mode]);

  /** Effect to cache the selected asset data on reload */
  useEffect(() => {
    window.localStorage.clear();
    window.localStorage.setItem('buyMode', mode.toString());

    switch (mode) {
    case 0: {
      break;
    }
    case 1: {
      if (backgroundData && selectedExtraId !== null) {
        window.localStorage.setItem('backgroundName', backgroundData.name);
        window.localStorage.setItem(
          'selectedExtraId',
          selectedExtraId.toString(),
        );
        window.localStorage.setItem(
          'backgroundPrice',
          backgroundData.price ? backgroundData.price : '0',
        );
        window.localStorage.setItem(
          'backgroundDescription',
          backgroundData.description,
        );
        window.localStorage.setItem(
          'backgroundBackground',
          backgroundData.background,
        );
      }
      break;
    }
    case 2: {
      if (audioData && selectedExtraId !== null) {
        window.localStorage.setItem('audioTitle', audioData.title);
        window.localStorage.setItem(
          'selectedExtraId',
          selectedExtraId.toString(),
        );
        window.localStorage.setItem(
          'audioPrice',
          audioData.price ? audioData.price.toString() : '0',
        );
        window.localStorage.setItem(
          'audioCharacterName',
          audioData.characterName,
        );
        window.localStorage.setItem('audioTrack', audioData.audioTrack);
        window.localStorage.setItem('backgroundBackground', 'true');
      }
      break;
    }
    case 3: {
      if (selectedPassId !== null) {
        window.localStorage.setItem('passId', selectedPassId.toString());
      }
      break;
    }
    default: {
      break;
    }
    }
  }, [mode, audioData, backgroundData, selectedPassId, selectedExtraId]);

  useEffect(() => {
    setTotalPrice(buyAmount * individualPrice);
  }, [buyAmount, individualPrice, passesPrices]);

  useEffect(() => {
    if ((mode === 1 || mode === 2) && price) {
      setIndividualPrice(Number(price));
    }
  }, [selectedExtraId, mode, price]);

  useEffect(() => {
    if (totalPrice <= 0) return;
    switch (mode) {
    case 0: {
      if (balanceETH > totalPrice) {
        setFunded(true);
      } else {
        setFunded(false);
      }
      break;
    }
    case 3: {
      if (passAllowance >= totalPrice) {
        setAllowed(true);
      } else {
        setAllowed(false);
      }
      checkBalance();
      break;
    }

    default: {
      if (extrasAllowance >= totalPrice) {
        setAllowed(true);
      } else {
        setAllowed(false);
      }
      checkBalance();
    }
    }
  }, [
    totalPrice,
    passAllowance,
    extrasAllowance,
    mode,
    address,
    balanceETH,
    checkBalance,
  ]);

  /** Effect to display the correct banner for the selected asset */
  useEffect(() => {
    switch (mode) {
    case 0: {
      setTitle('Random Fighter');
      setBanner(
        <img
          className={styles.CharacterBanner}
          src="assets/Components/BuyMode/CharacterBanner.gif"
          alt=""
        />,
      );
      if (charactersPrice > 0) {
        setIndividualPrice(charactersPrice);
      }
      break;
    }
    case 1: {
      setTitle('Background');
      if (backgroundData && selectedExtraId !== null && price) {
        setBanner(
          <BackgroundCard
            name={backgroundData.name}
            id={backgroundData.id}
            description={backgroundData.description}
            background={backgroundData.background}
          />,
        );
        setIndividualPrice(Number(price));
      }
      break;
    }
    case 2: {
      setTitle('Audio taunt');
      if (audioData && selectedExtraId !== null && price) {
        setBanner(
          <AudioCard
            title={audioData.title}
            id={audioData.id}
            characterName={audioData.characterName}
            audioTrack={audioData.audioTrack}
            viewMode
          />,
        );
        setIndividualPrice(Number(price));
      }
      break;
    }
    case 3: {
      setTitle('Fight pass');
      if (passesPrices && selectedPassId !== null) {
        setBanner(
          <PassCard
            passId={selectedPassId}
            fightsAmount={fightPerId[selectedPassId]}
            remainingFights={fightPerId[selectedPassId]}
            viewMode
          />,
        );
        setIndividualPrice(passesPrices[selectedPassId]);
      }
      break;
    }
    default: {
      break;
    }
    }
  }, [
    mode,
    price,
    selectedPassId,
    passesPrices,
    charactersPrice,
    audioData,
    backgroundData,
    selectedExtraId,
  ]);

  return (
    <section className={styles.MarketHomeBackground}>
      {address ? (
        <section className={styles.BuyContainer}>
          <div className={styles.ExitArrow}>
            <LinkContainer to={APP.MARKETPLACE}>
              <Nav.Link bsPrefix="Element">
                <img src="assets/Components/BuyMode/LeftArrow.png" alt="" />
              </Nav.Link>
            </LinkContainer>
            Marketplace
          </div>

          <div className={styles.Title}>
            <span className={styles.MintTitle}> Mint {title} </span>
            <span className={styles.PriceTitle}>
              {currencyText}: {individualPrice}
            </span>
          </div>
          {totalSupply && (
            <span className={styles.Subtitle}>
              Total supply: {totalSupply.toString()} NFTs
            </span>
          )}

          <div className={styles.Body}>
            {banner}
            <span className={styles.PriceMessage}> Price Per NFT</span>
            <span className={styles.Price}>
              {individualPrice} {currencyText}
            </span>
            <div className={styles.Amount}>
              <button
                type="button"
                aria-label="Decrease amount of this pass to buy"
                onClick={handleDecrease}
              >
                -
              </button>
              {buyAmount}
              <button
                type="button"
                aria-label="Increase amount of this item to buy"
                onClick={handleIncrease}
              >
                +
              </button>
            </div>
            <div className={styles.Resume}>
              <span className={styles.ResumeItem}>
                <span className={styles.ItemTitle}>
                  {buyAmount} {title} cost
                </span>
                <span className={styles.ItemAmount}>
                  {totalPrice.toFixed(2)}
                </span>
              </span>
              <span className={styles.ResumeTotal}>
                <span className={styles.TotalTitle}>
                  Total {mode !== 0 ? 'FIGHT' : 'ETH'}
                </span>
                <span className={styles.TotalAmount}>
                  {totalPrice.toFixed(2)}
                </span>
              </span>
            </div>
            {loading ? (
              <Loader />
            ) : (
              buyAmount !== 0 &&
              (funded ? (
                <button
                  type="button"
                  aria-label="Mint"
                  className={styles.MintButton}
                  onClick={handleDispatch}
                  style={{
                    filter:
                      totalSupply && totalSupply === MAX_SUPPLY
                        ? 'grayscale(1)'
                        : '',
                  }}
                >
                  {mode === 0 && 'Mint'}
                  {mode > 0 && (allowed ? 'Mint' : 'Approve')}
                </button>
              ) : (
                <span className={styles.Network}>Without balance</span>
              ))
            )}

            <span className={styles.Network}>
              {totalPrice > 0
                ? 'Remember the chain will add a fee to the transaction'
                : 'Please select an amount of items to buy'}
            </span>
          </div>
        </section>
      ) : (
        <NotificationModal
          message="To buy first connect your wallet"
          isConnect
        />
      )}
    </section>
  );
};

export default BuyMode;
