import React, { useEffect, useState } from 'react';
import { AnimatedOnScroll } from 'react-animated-css-onscroll';
import { HistoryDTO } from '../../lib/types';
import SkillBox from '../SkillBox/SkillBox';

import styles from './HistoryItem.module.css';
import { getWindowDimensions } from '../Header/Header';
import ImageLoader from '../ImageLoader/ImageLoader';

interface HistoryItemProps {
  fight: HistoryDTO;
}

/**
 * History item row component
 * @param {HistoryDTO} fight fight dto to display
 */
const HistoryItem: React.FC<HistoryItemProps> = ({ fight }) => {
  const [size, setSize] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setSize(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <AnimatedOnScroll animationIn="fadeInLeft" animateOnce delay={1000}>
      <div className={styles.ItemSeparator} />

      <div className={styles.ItemContainer}>
        <ImageLoader
          className={styles.Thumbnail}
          src={fight.thumbnail}
          alt=""
        />
        <div className={styles.NameBlock}>
          <span className={styles.Name}>{fight.character}</span>
        </div>
        <div className={styles.SkillBox}>
          <SkillBox
            skills={{
              punchPower: fight.punchPower.toString(),
              kickPower: fight.kickPower.toString(),
              speed: fight.speed.toString(),
              stamina: fight.stamina.toString(),
            }}
          />
        </div>

        {size > 1024 && (
          <span
            style={{ color: fight.winner ? 'greenyellow' : 'red' }}
            className={styles.Result}
          >
            {fight.winner ? 'Won' : 'Lost'}
          </span>
        )}
      </div>
    </AnimatedOnScroll>
  );
};

export default HistoryItem;
