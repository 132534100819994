import React, { useEffect, useState } from 'react';
import styles from './ClaimCard.module.css';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/reduxHooks';
import { claimXConsoles } from '../../redux/reducers/freeMintReducer/freeMintReducer';
import Loader from '../Loader/Loader';

interface ClaimCardProps {
  mode: number;
  tokenId?: number;
  claimableAmount: number;
}

/**
 * @param mode a number, 0 for whitelist merkle, 1 for normal claim, 2 for genesis claim
 * @param tokenId of the character that gives the claim in mode 1 or 2. Undefined in mode 0
 * @param claimableAmount the user can claim
 */
const ClaimCard: React.FC<ClaimCardProps> = ({
  mode,
  tokenId,
  claimableAmount,
}) => {
  ClaimCard.defaultProps = {
    tokenId: undefined,
  };
  const { totalTx } = useAppSelector(state => state.freeMint);

  const [amountToBuy, setAmountToBuy] = useState(0);
  const [claimed, setClaimed] = useState(false);
  const dispatch = useAppDispatch();
  const handleIncrease = () => {
    if (amountToBuy < 10 && amountToBuy < claimableAmount) {
      setAmountToBuy(amountToBuy + 1);
    }
  };
  const handleDecrease = () => {
    if (amountToBuy > 0) {
      setAmountToBuy(amountToBuy - 1);
    }
  };
  const handleClaim = () => {
    if (amountToBuy > 0) {
      const params = {
        isMerkle: mode === 0,
        tokenId,
        amount: amountToBuy,
      };
      dispatch(claimXConsoles(params));
      setClaimed(true);
    }
  };

  useEffect(() => {
    if (claimed) {
      setClaimed(false);
      setAmountToBuy(0);
    }
    // eslint-disable-next-line
  }, [totalTx]);

  return (
    <div className={styles.ClaimCardContainer}>
      <span className={styles.Title}>
        {mode === 0 && 'Whitelist '}
        {mode === 1 && '0xConsole '}
        {mode === 2 && 'Genesis '}
        Claim
      </span>
      {tokenId && <span className={styles.Subtitle}>TokenId: {tokenId}</span>}
      <div className={styles.ClaimCardSubcontainer}>
        <span className={styles.Description}>Claimable: {claimableAmount}</span>
        <div className={styles.ClaimCardCheckout}>
          {claimed ? (
            <Loader />
          ) : (
            <>
              <span className={styles.Amount}>
                <button
                  type="button"
                  aria-label="Decrease amount to claim"
                  className={styles.AmountButton}
                  onClick={handleDecrease}
                >
                  -
                </button>
                <div className={styles.AmountText}>{amountToBuy}</div>
                <button
                  type="button"
                  aria-label="Increase amount to claim"
                  className={styles.AmountButton}
                  onClick={handleIncrease}
                >
                  +
                </button>
              </span>
              <button
                type="button"
                aria-label="Claim this reward"
                className={styles.BuyButton}
                style={{ filter: amountToBuy === 0 ? 'grayscale(1)' : '' }}
                onClick={handleClaim}
              >
                Claim
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClaimCard;
