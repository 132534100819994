import React from 'react';
import { HistoryDTO } from '../../lib/types';
import HistoryItem from '../HistoryItem/HistoryItem';
import SkillBox from '../SkillBox/SkillBox';

import styles from './BoardPopUp.module.css';

interface BoardPopUpProps {
  tokenId: number;
  characterName: string;
  character: string;
  ranking: string;
  points: string;
  address: string;
  fights: HistoryDTO[] | undefined;
  punchPower: string | undefined;
  kickPower: string | undefined;
  speed: string | undefined;
  stamina: string | undefined;
}

/**
 * Board pop up component. Props:
 * @param {string} character the url to display an image of the character as
 * @param {string} characterName the name of the character
 * @param {number} tokenId token id of the character to display in the pop up
 * @param {string} points current total points of the character
 * @param {string} ranking position of the character in the scoreboard, based in his points
 * @param {string} address of the user owner of the character token id
 * @param {HistoryDTO[]?} fights array of fights to display in the history with needed props
 * @param {string?} punchPower stat of the character to display
 * @param {string?} kickPower stat of the character to display
 * @param {string?} speed stat of the character to display
 * @param {string?} stamina stat of the character to display
 */
const BoardPopUp: React.FC<BoardPopUpProps> = ({
  character,
  characterName,
  points,
  ranking,
  address,
  fights,
  punchPower,
  kickPower,
  speed,
  stamina,
  tokenId,
}) => {
  const historyBoard = fights
    ? fights.map((value: HistoryDTO, index) => (
      <HistoryItem
        key={`historyItem${value.character}${index}`}
        fight={value}
      />
    ))
    : null;

  return (
    <div className={styles.BoardPopUp}>
      <div className={styles.Header}>
        <span className={styles.Title}>{ranking}</span>
        <span className={styles.Subtitle}>
          Token Id:
          {` ${tokenId}`}
        </span>
      </div>
      <div className={styles.DescriptionContainer}>
        <span className={styles.ItemTitle}>Character</span>
        <span className={styles.ItemContent}>
          <img alt="" className={styles.Thumbnail} src={character} />
          {characterName}
        </span>

        <div className={styles.DescriptionSeparator}>
          <div className={styles.SeparatorLine} />
        </div>

        <span className={styles.ItemTitle}>Score </span>
        <span className={styles.ItemContent}>{points}</span>
        <div className={styles.DescriptionSeparator}>
          <div className={styles.SeparatorLine} />
        </div>
        <span className={styles.ItemTitle}>Fight Skills</span>
        <div className={styles.SkillBox}>
          <SkillBox
            skills={{
              punchPower: punchPower || '0',
              kickPower: kickPower || '0',
              speed: speed || '0',
              stamina: stamina || '0',
            }}
          />
        </div>
        <div className={styles.DescriptionSeparator}>
          <div className={styles.SeparatorLine} />
        </div>
        <span className={styles.ItemTitle}>Address </span>
        <span className={styles.ItemAddress}>{address}</span>
      </div>
      <div className={styles.HistoryContainer}>
        {fights && (
          <>
            <div className={styles.HistoryTitle}>
              {fights.length > 0 ? 'LAST FIGHTS' : 'NO FIGHTS YET '}
            </div>
            {historyBoard}
          </>
        )}
      </div>
    </div>
  );
};

export default BoardPopUp;
