import React, { useEffect, useState } from 'react';

import { Header, Footer } from '../../components';
import LoaderViewport from '../../components/LoaderViewport/LoaderViewPort';
import MyHome from '../../sections/MyNFTs-Sections/MyHome/MyHome';

const MyNFTs: React.FC = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoaded(true), 500); // Wait before render to avoid timing problems
  }, []);

  return (
    <>
      <LoaderViewport />
      {loaded && (
        <>
          <Header />
          <MyHome />
          <Footer />
        </>
      )}
    </>
  );
};

export default MyNFTs;
