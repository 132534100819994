import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/hooks/reduxHooks';
import {
  claimRewards,
  resetClaim,
} from '../../redux/reducers/apiReducer/apiReducer';
import { startConnection } from '../../redux/reducers/connectReducer/connectReducer';
import styles from './NotificationModal.module.css';
import Loader from '../Loader/Loader';
import { fetchBalance } from '../../redux/reducers/tokenReducer/tokenReducer';

interface NotificationModalProps {
  message: string;
  isConnect?: boolean;
  onBack?: () => void;
}

/**
 * Notification modal component. Props:
 * @param {string} message message to show in the notification modal
 * @param {boolean} isConnect OPTIONAL switch to change the button text and onClick. If defined its a modal for the wallet connection, else will be for reward claiming
 * @param {() => void} onBack OPTIONAL. Function to be triggered by the cross or back button
 */
const NotificationModal: React.FC<NotificationModalProps> = ({
  message,
  isConnect,
  onBack,
}) => {
  NotificationModal.defaultProps = {
    onBack: undefined,
    isConnect: undefined,
  };
  const { claimed } = useAppSelector(state => state.api);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  /** Trigger the connect modal */
  const handleConnect = () => {
    dispatch(startConnection());
  };
  const handleClaimRewards = () => {
    setLoading(true);
    dispatch(claimRewards());
  };

  useEffect(() => {
    if (!isConnect && claimed && onBack) {
      dispatch(fetchBalance());
      dispatch(resetClaim());
      onBack();
    }
  }, [claimed, isConnect, dispatch, onBack]);

  return (
    <div className={styles.NotificationContainer}>
      {onBack && !loading && (
        <button type="button" className={styles.CrossButton} onClick={onBack}>
          X
        </button>
      )}
      <span className={styles.Message}>{message}</span>
      {!loading ? (
        <div className={styles.Row}>
          {onBack && (
            <button
              type="button"
              className={styles.CancelButton}
              onClick={onBack}
            >
              Cancel
            </button>
          )}

          <button
            type="button"
            className={styles.ConnectButton}
            onClick={isConnect ? handleConnect : handleClaimRewards}
          >
            {isConnect ? 'Connect Wallet' : 'Claim Rewards'}
          </button>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default NotificationModal;
