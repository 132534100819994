import React, { useEffect, useState } from 'react';
import disableScroll from 'disable-scroll';

import Loader from '../Loader/Loader';
import styles from './LoaderViewport.module.css';
import { delayPageLoad } from '../../config';

/**
 * Loader component that uses all the screen size, and disables scrolls. So the user only sees the loader and background animated
 * It uses the delayPageLoad in config to set the time the component its rendered
 */
const LoaderViewport: React.FC = () => {
  const [toVanish, setToVanish] = useState(false);

  useEffect(() => {
    disableScroll.on();
    setTimeout(() => {
      disableScroll.off();
      setToVanish(true);
    }, delayPageLoad);
  }, []);

  return !toVanish ? (
    <section className={styles.LoaderContainer}>
      <Loader forScreen />
    </section>
  ) : null;
};
export default LoaderViewport;
