import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/esm/Dropdown';

import styles from './CustomSelector.module.css';

interface CustomSelectorProps {
  itemList: string[];
  selected: string;
  onItemSelected: (value: string) => void;
}

/**
 * Custom selector component, it receives the list of characters to filter and when the user selects on triggers
 * onItemSelected function passed
 * @param {string[]} itemList list of items
 * @param {string} selected selected character
 * @param {(value: number) => void} onItemSelected triggered when the user choose an item
 */
const CustomSelector: React.FC<CustomSelectorProps> = ({
  itemList,
  selected,
  onItemSelected,
}) => {
  const [list, setList] = useState<JSX.Element[]>([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setList(
      itemList.map(element => (
        <Dropdown.Item
          key={element}
          className={styles.Item}
          type="button"
          onClick={() => onItemSelected(element)}
        >
          {element}
        </Dropdown.Item>
      )),
    );
  }, [itemList, onItemSelected]);

  return (
    <Dropdown
      show={show}
      className={styles.CustomSelectorContainer}
      autoClose
      onMouseLeave={() => setShow(false)}
    >
      <Dropdown.Toggle
        className={styles.Toggle}
        variant=""
        onClick={() => setShow(true)}
      >
        {selected || 'Select a character'}
      </Dropdown.Toggle>

      <Dropdown.Menu className={styles.Menu}>{list}</Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomSelector;
