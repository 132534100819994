import React, { useEffect, useState } from 'react';

import { Footer, Header } from '../../components';
import LoaderViewport from '../../components/LoaderViewport/LoaderViewPort';
import { End, LandingHome, Launch } from '../../sections';

import styles from './Landing.module.css';

const Landing: React.FC = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoaded(true), 500); // Wait before render to avoid timing problems
  }, []);

  return (
    <>
      <LoaderViewport />
      {loaded && (
        <div className={styles.Landing}>
          <Header />
          <LandingHome />
          <Launch />
          <End />
          <Footer />
        </div>
      )}
    </>
  );
};
export default Landing;
